/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { createContext, useState, useContext, useEffect } from "react";
import { signInApp, signInConsultantApp, signUpApp } from "../services/Auth";
import { LoginResponseData, UserSasData, User } from "../types/auth";
interface AuthContextData {
  user: User | null;
  signed: boolean;
  is_consultant: boolean | null;
  authData: LoginResponseData | null;
  peopleSas?: UserSasData | null;
  Login(document: string, birth_date: string): Promise<any>;
  LoginConsultant(user: User, password: string): Promise<any>;
  Logout(): void;
  SignUp(
    document: string,
    birth_date: string,
    email: string,
    phone: string
  ): Promise<any>;
}

const AuthContext = createContext<AuthContextData>({} as AuthContextData);

export const AuthProvider: React.FC<any> = ({ children }) => {
  const [authData, setAuthData] = useState<LoginResponseData | null>(null);
  const [user, setUser] = useState<User | null>(null);
  const [peopleSas, setPeopleSas] = useState<UserSasData | null>(null);
  const [is_consultant, setIsConsultant] = useState<boolean | null>(
    sessionStorage.getItem("@App:is_consultant") === "true" ? true : false
  );
  const [signed, setSigned] = useState<boolean>(
    sessionStorage.getItem("@App:user") ? true : false
  );

  useEffect(() => {
    const storagedUser = sessionStorage.getItem("@App:login");
    const storangedPeopleSas = sessionStorage.getItem("@App:peopleSas");
    const storangedUser = sessionStorage.getItem("@App:user");
    const isConsultant = sessionStorage.getItem("@App:is_consultant");
    if (storagedUser !== null) {
      // const user = JSON.parse(storagedUser);
      setAuthData(JSON.parse(storagedUser));
      storangedPeopleSas !== null
        ? setPeopleSas(JSON.parse(storangedPeopleSas))
        : setPeopleSas(null);
      setSigned(true);
      storangedUser !== null
        ? setUser(JSON.parse(storangedUser))
        : setUser(null);
      isConsultant !== null
        ? setIsConsultant(JSON.parse(isConsultant))
        : setIsConsultant(null);
      // api!=undefined || api.defaults.headers.Authorization = `Bearer ${user.access}`;
    }
  }, []);

  const Login = async (document: string, birth_date: string) => {
    const login: LoginResponseData = await signInApp(document, birth_date);
    if (login.error === false && login.access != null) {
      setAuthData(login);
      login.user !== undefined ? setUser(login.user) : setUser(null);
      setSigned(true);
      setPeopleSas(login.context?.data ? login.context?.data : null);
      setSessionData(login, document, birth_date, false);
    }
    return login;
  };

  const LoginConsultant = async (user: User, password: string) => {
    const login: LoginResponseData = await signInConsultantApp(
      user.document,
      password,
      user.birth_date
    );
    if (login.error === false && login.access != null) {
      setAuthData(login);
      login.user !== undefined ? setUser(login.user) : setUser(null);
      setSigned(true);
      setPeopleSas(login.context?.data ? login.context?.data : null);

      setSessionData(login, user.document, user.birth_date, true);
      setIsConsultant(true);
    }
    return login;
  };

  const SignUp = async (
    document: string,
    birth_date: string,
    email: string,
    phone: string
  ) => {
    const login: LoginResponseData = await signUpApp(
      document,
      birth_date,
      email,
      phone
    );
    if (login != null) {
      setAuthData(login);
      setSigned(true);
      setPeopleSas(login.context?.data ? login.context?.data : null);
      login.user !== undefined ? setUser(login.user) : setUser(null);
      setSessionData(login, document, birth_date, false);
    }
    return login;
  };

  const setSessionData = (login, document, birth_date, isConsultant) => {
    sessionStorage.setItem(
      "@App:authData",
      JSON.stringify({ document: document, birth_date: birth_date })
    );
    sessionStorage.setItem("@App:user", JSON.stringify(login.user));
    sessionStorage.setItem("@App:login", JSON.stringify(login));
    sessionStorage.setItem("@App:is_consultant", JSON.stringify(isConsultant));
    sessionStorage.setItem(
      "@App:peopleSas",
      JSON.stringify(login.context?.data)
    );
    sessionStorage.setItem("access_token", `${login.access}`);
  };

  function Logout() {
    setAuthData(null);
    setSigned(false);
    setUser(null);
    setIsConsultant(false);
    sessionStorage.clear();
  }

  return (
    <AuthContext.Provider
      value={{
        is_consultant: is_consultant,
        user: user,
        signed: signed,
        authData: authData,
        peopleSas: peopleSas,
        Login,
        Logout,
        SignUp,
        LoginConsultant,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export function useAuth() {
  const context = useContext(AuthContext);

  return context;
}

import { Grid } from "@mui/material";
import * as React from "react";
import ReactSpeedometer from "react-d3-speedometer";
import { DiagnosticResponse } from "../../types/Diagnostic";

import { getPercent, getFormatedNumber } from "./utils";

interface ISpeedometersProps {
  diagnostic: DiagnosticResponse | undefined;
  themes: string[];
}
const textColor = "#AAA";
const Speedometers: React.FunctionComponent<ISpeedometersProps> = (props) => {
  const [themes] = React.useState(props.themes);
  const [diagnostic] = React.useState(props.diagnostic);

  return (
    <React.Fragment>
      {themes?.map((theme, i) => (
        <Grid
          item
          md={4}
          lg={4}
          sm={12}
          style={{
            textAlign: "center",
            width: "310px",
            height: "200px",
          }}
          key={i}
        >
          {theme}
          <ReactSpeedometer
            maxSegmentLabels={20}
            maxValue={100}
            segments={5}
            value={getPercent(theme, diagnostic)}
            currentValueText={`${getFormatedNumber(
              getPercent(theme, diagnostic)
            )}%`}
            textColor={textColor}
          />
        </Grid>
      ))}
    </React.Fragment>
  );
};

export default Speedometers;

import React from "react";
import { FormControlLabel, FormLabel, Radio, RadioGroup } from "@mui/material";

export interface Option{
    label: string;
    value: string;
}

interface Props {
  legend?: string;
  options?: Option[];
  name: string;
  defaultValue?: any;
  color: any;
  onChange?: (ev) => void;
}

export const RadioField = (props: Props) => {
  return (
    <React.Fragment>
      {props.legend ? <FormLabel component="legend">{props.legend}</FormLabel> : <></>}
      <RadioGroup
        aria-label={props.legend}
        value={props.defaultValue?props.defaultValue:''}
        name={props.name}
        id={props.name}
        onChange={props.onChange}
      >
        {props.options?.map((option, index) => (
          <FormControlLabel
            key={index}
            value={option.value?option.value:""}
            control={<Radio color={props.color!=null?props.color:'primary'} />}
            label={option.label}
           
          />
        ))}
      </RadioGroup>
    </React.Fragment>
  );
};

import React from "react";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from "@mui/material";
import create from "zustand";

interface ConfirmDialogStore {
  message: string;
  confirmButtonText: string;
  closeButtonText?: string;
  titleDialogText: string;
  onSubmit?: () => void;
  close: () => void;
}

const useConfirmDialogStore = create<ConfirmDialogStore>((set) => ({
  message: "Confirm",
  confirmButtonText: "OK",
  closeButtonText: "Fechar",
  titleDialogText: "Title",
  onSubmit: undefined,
  close: () => {
    set({
      onSubmit: undefined,
    });
  },
}));

export const confirmDialog = (
  message: string,
  confirmButtonText: string,
  onSubmit: () => void,
  titleDialogText: string,
  closeButtonText?: string
) => {
  useConfirmDialogStore.setState({
    message,
    onSubmit,
    confirmButtonText,
    titleDialogText,
    closeButtonText,
  });
};

const ConfirmDialog: React.FC = () => {
  const {
    message,
    confirmButtonText,
    closeButtonText,
    titleDialogText,
    onSubmit,
    close,
  } = useConfirmDialogStore();

  return (
    <Dialog open={Boolean(onSubmit)} onClose={close} maxWidth="sm" fullWidth>
      <DialogTitle>{titleDialogText}</DialogTitle>
      <DialogContent>
        <div dangerouslySetInnerHTML={{ __html: message }} />
      </DialogContent>
      <DialogActions>
        {closeButtonText !== undefined ? (
          <Button color="error" variant="contained" onClick={close}>
            {closeButtonText}
          </Button>
        ) : (
          <></>
        )}
        <Button
          color="primary"
          variant="contained"
          onClick={() => {
            if (onSubmit) {
              onSubmit();
            }
            close();
          }}
        >
          {confirmButtonText}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default ConfirmDialog;

/* eslint-disable @typescript-eslint/no-unused-vars */
import api from "./api";
import { AxiosResponse } from "axios";
import {
  Campaign,
  PageForm,
  ResponseQuestions,
  ResponseForm,
} from "../types/campaign";

const listCampaigns = async (
  isCompany = false,
  is_public: boolean | null = null,
  externalUserUuid: string | undefined = undefined,
  companyUuid = null,
  is_consultant = false
) => {
  let data = "";
  if (isCompany === false) {
    data = "?only_company=0";
  }
  if (is_public !== null) {
    data += data === "" ? "?" : "&";
    const qr = is_public === true ? 1 : 0;
    data += `is_public=${qr}`;
  }
  if(externalUserUuid !== undefined){                                    
    data += data === "" ? "?" : "&";
    data += `external_user=${externalUserUuid}`;
  }
  if(companyUuid !== null && companyUuid !== ''){
    data += data === "" ? "?" : "&";
    data += `company=${companyUuid}`;
  }
  if(is_consultant===true){
    data += data === "" ? "?" : "&";
    const isConsultant = is_consultant === true ? 1 : 0;
    data += `is_selectable_by_consultant=${isConsultant}`;
  }
  data += data === "" ? "?" : "&";
  data += `is_selectable_by_self_service=1`
  const response: AxiosResponse = await api.get(
    `api/external/campaigns/${data}`//externalUserUuid / companyUuid
  );
  const campaign: Campaign[] = response.data;
  return campaign;
};

const getCampaign = async (uuid) => {
  const response: AxiosResponse = await api.get(
    `api/external/campaigns/${uuid}/`
  );
  const campaign: Campaign = response.data;
  return campaign;
};
interface ConsultPagesData {
  data: PageForm[];
  count: number;
}

const loadFullPages = async (formUuid: string) => {
  return await getFormPages(formUuid);
};
const getFormPages = async (formUUid: string) => {
  const response: AxiosResponse = await api.get(
    `api/external/forms/${formUUid}/pages/`
  );
  const result: ConsultPagesData = response.data;
  return result.data;
};

const createFormResponse = async (
  formUuid,
  campaignUuid,
  externalUserUuid,
  companyUuid='',
  isCompleted = false,
  averange = 1,
  consultantUuid = ''
) => {
  const requestData = {
    external_user_uuid: externalUserUuid,
    company_uuid: companyUuid,
    form_uuid: formUuid,
    campaing_uuid: campaignUuid,
    average: averange,
    is_completed: isCompleted,
    system_integration: 3,
    consultant_uuid: consultantUuid,
  };
  const response: AxiosResponse = await api.post(
    "api/external/responseForm/",
    requestData
  );
  const result: ResponseForm = response.data;
  return result;
};

const updateFormResponse = async (formResponse, progress) => {
  if (progress !== null) {
    const requestData = {
      progress: progress,
      average: formResponse.average,
      is_completed: formResponse.is_completed,
    };
    if (requestData.progress !== null && !isNaN(requestData.progress)) {
      const response: AxiosResponse = await api.patch(
        `api/external/responseForm/${formResponse.uuid}/`,
        requestData
      );
      const result: ResponseForm = response.data;
      return result;
    }
  }
};

interface ResponseFormSave {
  error: boolean;
  responseQuestions: ResponseQuestions[];
}
const sendResponseFormAnswers = async (formResponseUuid, responses) => {
  const requestData = {
    formResponse_uuid: formResponseUuid,
    formResponseQuestions: responses,
  };
  const response: AxiosResponse = await api.post(
    "api/external/responseFormQuestions/",
    requestData
  );
  const result: ResponseFormSave = response.data;
  return result;
};
const listFormResponses = async (externalUserUuid) => {
  const response: AxiosResponse = await api.get(
    `api/external/responseForm/list/${externalUserUuid}/`
  );
  const result: ResponseForm[] = response.data;
  return result;
};
const getFormResponses = async (formResponseUuid) => {
  const response: AxiosResponse = await api.get(
    `api/external/responseForm/${formResponseUuid}/`
  );
  const result: ResponseForm = response.data;
  return result;
};
const listFormResponsesCompany = async (externalUserUuid, companyUuid, onlyValids=false) => {
  let qr = '';
  if(onlyValids) {
    qr = `?onlyValids=1`;
  }
  const response: AxiosResponse = await api.get(
    `api/external/responseForm/list/${externalUserUuid}/${companyUuid}/${qr}`
  );
  const result: ResponseForm[] = response.data;
  return result;
};

const listFormResponse = async (externalUserUuid) => {
  const response: AxiosResponse = await api.get(
    `api/external/responseForm/list/${externalUserUuid}/`
  );
  const result: ResponseForm[] = response.data;
  return result;
};

const responsesFormResponse = async (formResponseUuid) => {
  // localhost:8000/api/external/responseFormQuestions/responses/91b51259-e813-4ca5-9ab2-b5e9d4555f02/
  const response: AxiosResponse = await api.get(
    `/api/external/responseFormQuestions/responses/${formResponseUuid}/`
  );
  return response.data;
};

export {
  listCampaigns,
  getFormPages,
  getCampaign,
  loadFullPages,
  createFormResponse,
  sendResponseFormAnswers,
  updateFormResponse,
  listFormResponses,
  listFormResponsesCompany,
  responsesFormResponse,
  getFormResponses,
  listFormResponse,
};

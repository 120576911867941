import * as React from "react";
import Avatar from "@mui/material/Avatar";

function stringToColor(string: string) {
  let hash = 0;
  let i;

  /* eslint-disable no-bitwise */
  for (i = 0; i < string.length; i += 1) {
    hash = string.charCodeAt(i) + ((hash << 5) - hash);
  }

  let color = "#";

  for (i = 0; i < 3; i += 1) {
    const value = (hash >> (i * 8)) & 0xff;
    color += `00${value.toString(16)}`.substr(-2);
  }
  /* eslint-enable no-bitwise */

  return color;
}

function stringAvatar(name: string) {
  return {
    sx: {
      bgcolor: stringToColor(name),
    },
    children: `${name.split(" ")[0][0]}${name.split(" ")[1][0]}`,
  };
}

interface IAvatarHeadProps {
  name?: string;
  imageUri?: string;
}

const AvatarHead: React.FC<IAvatarHeadProps> = (props) => {
  let avatar = <></>;
  if (props.imageUri !== undefined) {
    avatar = (
      <Avatar
        alt={props.name}
        src={props.imageUri}
        sx={{ width: 24, height: 24 }}
      />
    );
  }else if(props.name){
      avatar = (<Avatar {...stringAvatar(props.name)} />)
  }
  return <React.Fragment>{avatar}</React.Fragment>;
};

export default AvatarHead;

import { useController } from 'react-hook-form';

import Styles from './styles';

const Index = (props: unknown | any): JSX.Element => {
  const { control, defaultValue, name } = props;
  const {
    field: { ref, ...inputProps },
    // fieldState: { invalid, isTouched, isDirty },
    // formState: { touchedFields, dirtyFields },
  } = useController({
    name,
    control,
    // rules: { required: true },
    defaultValue: defaultValue || '',
  });
  const { variant, size, error, mask } = props;

  return (
    <Styles.Mask mask={mask} {...inputProps}>
      {(maskProps: unknown | any) => (
        <Styles.Text
          {...maskProps}
          variant={variant}
          size={size}
          error={error}
          inputRef={ref}
          {...props}
        />
      )}
    </Styles.Mask>
  );
};

Index.defaultProps = {
  variant: 'outlined',
  size: 'normal',
};

export default Index;
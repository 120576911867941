/* eslint-disable @typescript-eslint/no-redeclare */

import React from "react";
import { Switch, Route, Redirect } from "react-router-dom";
// import SignRoutes from "./SignRoutes";
// import OtherRoutes from "./OtherRoutes";

import { useAuth } from "../contexts/auth";

import Login from "../pages/LoginPage";
// import Register from "../pages/Register";
// import LandingPage from "../pages/LandingPage";
import CompanyPage from "../pages/CompanyPage";
import CampaignsSelect from "../pages/CampaingsSelect";
import Home from "../pages/Home";
import Survey from "../pages/Survey";
import { LoadForm } from "../pages/Survey/loadForm";
import CampaignPage from "../pages/CampaignPage";
import { OpenPublic } from "../pages/Survey/openPublic";
import CampaignListPublic from "../pages/CampaignListPublic";
import Diagnostic from "../pages/Diagnoistic";
import ConsultantLogin from "../pages/ConsultantLogin";
// import { TransitionGroup, CSSTransition } from "react-transition-group";
// import { CSSTransition } from "react-transition-group";
// import LandingPage from "../pages/LandingPage";
import "../animation.css";
import SurveyResponses from "../pages/SurveryReponses";
import ConsultantPanel from "../pages/ConsultantPanel";
import ClientDetails from "../pages/ConsultantPanel/client_details";
const ProtectedRoute = ({ component: Component, isAuth, ...rest }) => {
  return (
    <Route
      {...rest}
      render={(props) =>
        isAuth ? <Component {...props} /> : <Redirect exact to="/login" />
      }
    />
  );
};


const ConsultantProtectedRoute = ({ component: Component, isAuth, isConsultant, ...rest }) => {
  return (
    <Route
      {...rest}
      render={(props) =>
        (isAuth && isConsultant) ? <Component {...props} /> : <Redirect exact to="/login" />
      }
    />
  );
};

const Routes: React.FC = () => {
  const { signed,is_consultant } = useAuth();
  return (
    <Switch>
      <Route exact path="/" component={Home} />
      <Route exact path="/campaigns" component={CampaignListPublic} />
      <ProtectedRoute
        exact
        path="/companies"
        component={CompanyPage}
        isAuth={signed}
      />
      <ConsultantProtectedRoute
        exact
        path="/consultant_panel"
        component={ConsultantPanel}
        isAuth={signed}
        isConsultant={is_consultant}
      />
      <ConsultantProtectedRoute
        exact
        path="/consultant_panel/client_detail/:clientUuid/:companyUuid?"
        component={ClientDetails}
        isAuth={signed}
        isConsultant={is_consultant}
      />
      <ProtectedRoute
        exact
        path="/companies/:campaignUuid"
        component={CompanyPage}
        isAuth={signed}
      />
      <ProtectedRoute path="/landing" component={CompanyPage} isAuth={signed} />
      <ProtectedRoute
        exact
        path="/campainsSelect"
        component={CampaignsSelect}
        isAuth={signed}
      />
      <ProtectedRoute
        exact
        path="/campainsSelect/:companyUuid"
        component={CampaignsSelect}
        isAuth={signed}
      />
      <ProtectedRoute
        exact
        path="/campainsSelect/:companyUuid/:campaignUuid"
        component={CampaignsSelect}
        isAuth={signed}
      />
      <ProtectedRoute
        path="/editForm/:responseUuid"
        component={LoadForm}
        isAuth={signed}
      />
      <Route path="/openSurvey/:campaignUuid" component={OpenPublic} />
      <Route path="/survey/:uuid" component={Survey} />
      <Route exact path="/diagnostic/:uuid" component={Diagnostic} />
      <Route
        exact
        path="/surveyResponses/:formResponseUuid"
        component={SurveyResponses}
      />
      <Route exact path="/consultant_login" component={ConsultantLogin} />
      <Route
        exact
        path="/campaignPage/:campaignUuid"
        component={CampaignPage}
      />
      <Route path="/login" component={Login} />
    </Switch>
  );
};
export default Routes;

import React from "react";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import TextField from "@mui/material/TextField";
import MaskInput from "../../components/Field/MaskInput";
import { Button, Typography } from "@mui/material";
import { addCompanyUser, editCompany } from "../../services/CompanyService";
import { useAuth } from "../../contexts/auth";
import history from "../../history";
import HelperCnpjCpf from "../../helpers/CnpjCpf";
import { UserCompanies } from "../../types/auth";

interface AddCompanyProps {
  open: boolean;
  company?: UserCompanies | null;
  handleClose: () => void;
  beforeSubmit?: () => void;
}
interface IFormInputs {
  document?: string;
  phone: string;
  email: string;
}
const validationSchema = yup
  .object({
    document: yup
      .string()
      .min(18, "O CNPJ precisa ter todos os numeros")
      .required("O CNPJ é obrigatório"),
    phone: yup.string().required("Telefone é obrigatório"),
    email: yup
      .string()
      .email("Formato inválido")
      .required("O E-mail é obrigatório"),
  })
  .required();
export default function AddModalCompany(props: AddCompanyProps) {
  const { peopleSas, user } = useAuth();
  const {
    handleSubmit,
    reset,
    register,
    control,
    formState: { errors },
  } = useForm<IFormInputs>({
    resolver: yupResolver(validationSchema),
  });
  const [submitError, setSubmitError] = React.useState("");

  React.useEffect(() => {
    // setUserCompanies(authData?.context?.data.ListaVinculo);

    if (props.company !== null) {
      reset({
        document: HelperCnpjCpf.formatCnpj(props.company?.document),
        email: props.company?.email,
        phone: props.company?.phone,
      });
    } else {
      reset({
        document: "",
        email: "",
        phone: "",
      });
    }
  }, [props, reset]);

  const onSubmitAdd = async (data: IFormInputs) => {
    if (user !== null && user.external_user.uuid !== undefined) {
      if(HelperCnpjCpf.validarCNPJ(data.document)){
        let result = await addCompanyUser(
          user.external_user.uuid,
          data.document,
          data.email,
          data.phone,
          peopleSas?.CodParceiro
        );
        if (result?.error === false) {
          if (props.beforeSubmit !== undefined) {
            props.beforeSubmit();
          }
          reset();
          history.push("/companies");
        } else {
          if (result?.message !== undefined) {
            setSubmitError(result.message);
          } else {
            setSubmitError("Erro no cadastro, tente novamente.");
          }
        }
      }else{
        setSubmitError("Cnpj Inválido");
      }
    }
    return false;
  };

  const onSubmitEdit = async (data: IFormInputs) => {
    if (user !== null && user.external_user.uuid !== undefined) {
      let ts = {
        email: data.email,
        phone: data.phone,
      };

      const result = await editCompany(props.company?.uuid, ts);
      if (result?.error === false) {
        if (props.beforeSubmit !== undefined) {
          props.beforeSubmit();
        }
        reset();
        history.push("/companies");
      } else {
        if (result?.message !== undefined) {
          setSubmitError(result.message);
        } else {
          setSubmitError("Erro no cadastro, tente novamente.");
        }
      }
    }
    return false;
  };
  const mySubmit = (e) => {
    e.preventDefault();
    // do your early validation here
    if (props.company === null) {
      handleSubmit(onSubmitAdd)(e);
    } else {
      handleSubmit(onSubmitEdit)(e);
    }
  };

  const getFormInputs = () => {
    return (
      <React.StrictMode>
        {props.company !== null ? (
          <TextField
            {...register("document")}
            label="CNPJ"
            fullWidth
            required
            disabled
            color="primary"
            margin="normal"
          />
        ) : (
          <React.Fragment>
            <MaskInput
              control={control}
              name="document"
              label="CNPJ"
              fullWidth
              required
              errors={errors}
              color="primary"
              margin="normal"
              mask="99.999.999/9999-99"
            />
          </React.Fragment>
        )}
        <Typography
          style={{ color: "#F75252" }}
          variant="body2"
          component="div"
        >
          {errors.document?.message}
          {submitError}
        </Typography>
        <MaskInput
          control={control}
          name="phone"
          label="Telefone"
          fullWidth
          required
          errors={errors}
          color="primary"
          margin="normal"
          mask="(99)9 9999-9999"
        />

        <TextField
          color="primary"
          margin="normal"
          required
          fullWidth
          label="E-mail"
          {...register("email")}
        />
        <Typography
          style={{ color: "#F75252" }}
          variant="body2"
          component="div"
        >
          {errors.email?.message}
        </Typography>
      </React.StrictMode>
    );
  };

  return (
    <Dialog open={props.open} onClose={props.handleClose} sx={{ zIndex: 0 }}>
      <form onSubmit={mySubmit}>
        <DialogTitle>Cadastro de Empresas</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Insira as informações da empresa para cadastro ou atualização de
            vinculo.
          </DialogContentText>
          {getFormInputs()}
        </DialogContent>
        <DialogActions>
          <Button onClick={props.handleClose}>Cancel</Button>
          <Button type="submit" color="success"
           style={{ color: "#FFFFFF" }} variant="contained">
            Salvar
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  );
}

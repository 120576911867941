import React, { useEffect } from "react";
// import { CheckboxField } from "../../components/Checkbox";
import { RadioField } from "../../components/Field/Radio";
import { Select } from "../../components/Field/Select";
import { Text } from "../../components/Field/Text";
import { QuestionForm } from "../../types/campaign";
import { CheckboxField } from "../../components/Field/CheckboxField";
import { FormGroup, FormLabel } from "@mui/material";

interface questionProps {
  questionForm: QuestionForm;
  formik;
}

export const QuestionComponent: React.FC<questionProps> = (props) => {
  const formik = props.formik;
  const [questionForm, setQuestionForm] = React.useState<QuestionForm>(
    props.questionForm
  );
  useEffect(() => {
    setQuestionForm(props.questionForm);
  }, [props]);

  const checkAnswersOptions = (ElementQuestion) => {
    return (
      ElementQuestion.question.question_type.has_offered_answer === true &&
      ElementQuestion.question_offered_answer !== undefined &&
      ElementQuestion.question_offered_answer.length > 0
    );
  };
  const getQuestionField = (ElementQuestion) => {
    if (
      ElementQuestion.question.question_type.slug ===
        "caixa-de-selecao-para-unica-opcao" &&
      checkAnswersOptions(ElementQuestion)
    ) {
      return (
        <RadioField
          legend={ElementQuestion.question.description}
          options={ElementQuestion.question_offered_answer?.map((answer) => {
            return { label: answer.value, value: answer.uuid };
          })}
          name={ElementQuestion.uuid}
          color="success"
          onChange={formik.handleChange}
          defaultValue={formik.values[ElementQuestion.uuid]}
        />
      );
    } else if (ElementQuestion.question.question_type.slug === "texto-curto") {
      return (
        <Text
          label={ElementQuestion.question.description}
          name={ElementQuestion.uuid}
          onChange={formik.handleChange}
          defaultValue={formik.values[ElementQuestion.uuid]}
        />
      );
    } else if (ElementQuestion.question.question_type.slug === "text-longo") {
      return (
        <Text
          label={ElementQuestion.question.description}
          name={ElementQuestion.uuid}
          multiline={true}
          maxRows={4}
          onChange={formik.handleChange}
          defaultValue={formik.values[ElementQuestion.uuid]}
        />
      );
    } else if (
      ElementQuestion.question.question_type.slug === "selecionar-uma-opcao"
    ) {
      return (
        <Select
          label={ElementQuestion.question.description}
          name={ElementQuestion.uuid}
          options={ElementQuestion.question_offered_answer?.map((answer) => {
            return { label: answer.value, value: answer.uuid };
          })}
          onChange={formik.handleChange}
          default={formik.values[ElementQuestion.uuid]}
        />
      );
    } else if (
      ElementQuestion.question.question_type.slug ===
      "caixa-de-selecao-para-mutiplas-opcoes"
    ) {
      const checkProp = (optionVal, defaultProps) => {
        if (defaultProps !== undefined) {
          const propss = defaultProps.find((prop) => {
            if (prop === undefined) {
              return false;
            }
            var val = prop[0] !== undefined ? prop[0] : "";
            return val === optionVal;
          });

          if (propss !== undefined && propss.length > 0) {
            return true;
          }
          return false;
        }
        return false;
      };
      const options = ElementQuestion.question_offered_answer?.map((answer) => {
        return { label: answer.value, value: answer.uuid };
      });
      return (
        <FormGroup>
          {ElementQuestion.question.name ? (
            <FormLabel component="legend">
              {ElementQuestion.question.description}
            </FormLabel>
          ) : (
            <></>
          )}
          {options.map((option, index) => {
            return (
              <CheckboxField
                key={index}
                label={option.label}
                value={option.value}
                name={ElementQuestion.uuid + "." + index}
                id={ElementQuestion.uuid + "." + index}
                color="success"
                checked={checkProp(
                  option.value,
                  formik.values[ElementQuestion.uuid]
                )}
                onChange={formik.handleChange}
              />
            );
          })}
        </FormGroup>
      );
    } else {
      console.log(ElementQuestion);
      return <div>Tipo desconhecido</div>;
    }
  };

  return <React.Fragment>{getQuestionField(questionForm)}</React.Fragment>;
};

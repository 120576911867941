/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useEffect } from "react";
import {
  Container,
  CssBaseline,
  createTheme,
  Card,
  CardContent,
  Typography,
  Grid,
  Button,
  Box,
  Alert,
  IconButton,
  CardActionArea,
  CardMedia,
} from "@mui/material";
import { useAuth } from "../../contexts/auth";
import { Link as RouterLink } from "react-router-dom";
// import AddCompany from "./add";
import { deleteCompany, getUserCompanies } from "../../services/CompanyService";
import { Company } from "../../types/Company";
import {
  createFormResponse,
  getCampaign,
  listFormResponses,
  listFormResponsesCompany,
} from "../../services/Campaign";
import { Campaign, ResponseForm } from "../../types/campaign";
import Responses from "../../components/Responses";
import history from "../../history";
import { RouteComponentProps, StaticContext } from "react-router";
import { useSurvey } from "../../contexts/survey";
import { makeStyles } from "@mui/styles";
import AddModalCompany from "./addModalCompany";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import Swal from "sweetalert2";
import "@sweetalert2/theme-material-ui";
import btNovaAvaliacao from "../../assets/img/novaAvaliacao.png";
import btMinhasAvaliacoes from "../../assets/img/minhasAvaliacoes.png";
import { Element, animateScroll as scroll, scroller } from "react-scroll";
import { UserCompanies } from "../../types/auth";

interface MatchParams {
  campaignUuid?: string;
}
type LocationState = {};

const useStyles = makeStyles((theme: any) => ({
  nameSelectedCompany: {
    textAlign: "left",
    marginBottom: "25px",
    borderBottom: "0.2px solid #FFFFFF",
  },
}));

const theme = createTheme();
theme.palette.background = {
  default: "#ffffff",
  paper: "#050505",
};

const CompanyPage = (
  props: RouteComponentProps<MatchParams, StaticContext, LocationState>
) => {
  const { authData, peopleSas, user } = useAuth();
  const [showAdd, setShowAdd] = React.useState(false);
  const [showDiagnostics, setShowDiagnostics] = React.useState(false);
  const [userCompanies, setUserCompanies] = React.useState<UserCompanies[] | null>();
  const [editCompany, setEditCompany] = React.useState<UserCompanies | null>(null);
  const [formResponses, setFormResponses] = React.useState<
    ResponseForm[] | []
  >();
  const [companySelected, setCompany] = React.useState<UserCompanies>();
  const { savedCampaign, ClearCampaign } = useSurvey();
  // const classes = useStyles();

  useEffect(() => {
    // setUserCompanies(authData?.context?.data.ListaVinculo);
  }, [authData, formResponses, user]);

  const loadCompanies = async (us) => {
    if (
      us !== undefined &&
      us?.external_user !== null &&
      us?.external_user.uuid !== undefined
    ) {
      const loadedCompanies = await getUserCompanies(us?.external_user.uuid);

      setUserCompanies(loadedCompanies);
      if (loadedCompanies.length > 0) {
        await changeCompany(loadedCompanies[0]);
        await loadFormResponsesCompany(loadedCompanies[0].uuid);
      } else {
        Swal.fire({
          title:
            "Vimos que você não possui nenhuma empresa vinculada, deseja vincular agora?",
          icon: "warning",
          confirmButtonText: "SIM",
          showCancelButton: true,
          cancelButtonText: "NÃO",
        }).then(async (result) => {
          if (result.isConfirmed) {
            setShowAdd(true);
          }
        });
      }
    }
  };

  const loadMyResponses = async () => {
    if (user !== undefined && user?.external_user.uuid !== undefined) {
      const lsFormResponses = await listFormResponses(user?.external_user.uuid);
      setFormResponses(lsFormResponses);
    }
  };

  async function loadFormResponsesCompany(companyUuid) {
    if (user !== undefined && user?.external_user.uuid !== undefined) {
      if (companyUuid !== "") {
        const lsFormResponses = await listFormResponsesCompany(
          user?.external_user.uuid,
          companyUuid
        );
        if (lsFormResponses !== undefined) {
          setFormResponses(lsFormResponses);
        }
      } else {
        if (user !== undefined && user?.external_user.uuid !== undefined) {
          const lsFormResponses = await listFormResponses(
            user?.external_user.uuid
          );
          if (lsFormResponses !== undefined) {
            setFormResponses(lsFormResponses);
          }
        }
        // setFormResponses([]);
      }
    }
  }

  const afterAddCompany = (isShowAdd) => {
    loadCompanies(authData?.user);
    setShowAdd(isShowAdd);
  };

  const changeCompany = async (company: UserCompanies | null) => {
    if (company !== null) {
      if (props.match.params.campaignUuid !== undefined) {
        const campaign = await getCampaign(props.match.params.campaignUuid);

        const formResponseRet = await createFormResponse(
          campaign.form.uuid,
          campaign.uuid,
          user?.external_user.uuid,
          company.uuid
        );
        history.push(`/survey/${campaign.uuid}`, {
          Campaign: campaign,
          formData: {},
          ResponseForm: formResponseRet,
        });
      } else {
        setCompany(company);
        loadFormResponsesCompany(company.uuid);
      }
    } else {
      setCompany(undefined);
      loadMyResponses();
    }
    setShowDiagnostics(false);
  };

  const gotoNewAvaliation = (savedCampaign: Campaign | null = null) => {
    if (companySelected !== undefined && savedCampaign == null) {
      history.push(`/campainsSelect/${companySelected?.uuid}`, {
        company: companySelected,
      });
    } else if (companySelected !== undefined && savedCampaign !== null) {
      history.push(
        `/campainsSelect/${companySelected?.uuid}/${savedCampaign.uuid}`,
        {
          company: companySelected,
          savedCampaign: savedCampaign,
        }
      );
    } else {
      history.push("/campainsSelect");
    }
  };

  const toggleAvaliations = () => {
    if (showDiagnostics === true) {
      scroll.scrollToTop();
      setShowDiagnostics(false);
    } else {
      setShowDiagnostics(true);
      scroller.scrollTo("scroll-to-element", {
        duration: 800,
        delay: 0,
        smooth: "easeInOutQuart",
      });
    }
  };

  const showAlertSavedCampaign = () => {
    if (savedCampaign !== null && companySelected === undefined) {
      return (
        <Alert severity="info">
          Você tentou acessar um diagnostico que necessita de uma empresa
          vinculada para ser respondido, selecione uma empresa para acessa-lo
        </Alert>
      );
    } else if (
      savedCampaign !== null &&
      savedCampaign !== undefined &&
      companySelected !== undefined
    ) {
      return (
        <Alert severity="info" onClose={() => {ClearCampaign()}}>
          Você tentou acessar o diagnostico, {savedCampaign.name}{" "}
          <Button
            color="success"
            variant="text"
            style={{ color: "#FFFFFF", marginLeft: "5px", marginRight: "5px" }}
            onClick={() => gotoNewAvaliation(savedCampaign)}
            component={RouterLink}
            to="#"
          >
            Clique aqui
          </Button>
          e acesse
        </Alert>
      );
    }
  };
  const handleDeleteCompany = (company: UserCompanies) => {
    Swal.fire({
      title: "Você realmente deseja excluír a empresa " + company.name,
      icon: "warning",
      confirmButtonText: "SIM",
      showCancelButton: true,
      cancelButtonText: "NÃO",
    }).then(async (result) => {
      if (result.isConfirmed) {
        await deleteCompany(company.uuid, user?.external_user.uuid);
        loadCompanies(authData?.user);
        Swal.fire("Excluído!", "A empresa foi excluída", "success");
      }
    });
  };

  const handleEditCompany = (company: UserCompanies) => {
    setEditCompany(company);
    setShowAdd(true);
  };
  const openModalAddCompany = () => {
    if (showAdd === true) {
      setShowAdd(false);
      setEditCompany(null);
    } else {
      setShowAdd(true);
    }
  };
  if (userCompanies === undefined) {
    loadCompanies(authData?.user);
  }
  if (formResponses === undefined && userCompanies === null) {
    loadMyResponses();
  }
  const classes = useStyles();
  return (
    <React.Fragment>
      <CssBaseline />
      <Grid container spacing={2} style={{ marginTop: "50px" }}>
        <Container maxWidth="lg" component="main">
          {showAlertSavedCampaign()}
          <Typography gutterBottom component="h2" variant="h3" color="yellow">
            Olá, {user?.name}
          </Typography>
          <Typography gutterBottom variant="body1" component="div">
            Meus Cadastros
          </Typography>
          {userCompanies !== undefined && (
            <React.Fragment>
              <Card
                sx={{ minWidth: 275 }}
                variant={
                  companySelected !== undefined ? "outlined" : "elevation"
                }
              >
                <CardContent
                  style={
                    companySelected !== undefined ? {} : { color: "yellow" }
                  }
                >
                  <Typography gutterBottom variant="h5" component="div">
                    PF - {user?.name}
                  </Typography>
                  <Typography variant="body2" component="div">
                    {user?.document}
                  </Typography>
                  <Button
                    color={
                      companySelected !== undefined ? "success" : "primary"
                    }
                    variant="contained"
                    style={{ color: "#FFFFFF", float: "right", top: "-30px" }}
                    onClick={() => changeCompany(null)}
                  >
                    {companySelected !== undefined
                      ? "Acessar Diagnóstico"
                      : "Selecionado"}
                  </Button>
                </CardContent>
              </Card>
              {userCompanies?.map((company, ind) => {
                return (
                  <React.Fragment key={ind}>
                    <Card
                      sx={{ minWidth: 275 }}
                      key={ind}
                      variant={
                        company.document !== companySelected?.document
                          ? "outlined"
                          : "elevation"
                      }
                    >
                      <CardContent
                        style={
                          company.document !== companySelected?.document
                            ? {}
                            : { color: "yellow" }
                        }
                      >
                        <Typography gutterBottom variant="h5" component="div">
                          PJ - {company.name}
                        </Typography>
                        <Typography variant="body2" component="div">
                          {company.document}
                        </Typography>
                        <IconButton
                          aria-label="excluír"
                          color="error"
                          size="large"
                          onClick={() => handleDeleteCompany(company)}
                        >
                          <DeleteIcon fontSize="inherit" />
                        </IconButton>
                        <IconButton
                          aria-label="editar"
                          color="warning"
                          size="large"
                          onClick={() => handleEditCompany(company)}
                        >
                          <EditIcon fontSize="inherit" />
                        </IconButton>
                        <Button
                          color={
                            company.document !== companySelected?.document
                              ? "success"
                              : "primary"
                          }
                          variant="contained"
                          style={{
                            color: "#FFFFFF",
                            float: "right",
                            top: "-30px",
                          }}
                          onClick={() => changeCompany(company)}
                        >
                          {company.document !== companySelected?.document
                            ? "Acessar Diagnóstico"
                            : "Selecionado"}
                        </Button>
                      </CardContent>
                    </Card>
                  </React.Fragment>
                );
              })}
            </React.Fragment>
          )}
          <Box style={{ marginTop: "25px" }}>
            <Button
              color="success"
              variant="contained"
              style={{ color: "#FFFFFF" }}
              onClick={openModalAddCompany}
            >
              Adicionar Empresa
            </Button>
          </Box>
          <Typography
            gutterBottom
            variant="h5"
            component="div"
            style={{ marginTop: "25px" }}
          >
            O que você deseja fazer?
          </Typography>
          <Box style={{ marginTop: "50px" }}>
            <AddModalCompany
              open={showAdd}
              company={editCompany}
              handleClose={() => {
                openModalAddCompany();
              }}
              beforeSubmit={() => {
                afterAddCompany(false);
              }}
            />
            <Typography
              gutterBottom
              variant="h5"
              component="div"
              className={classes.nameSelectedCompany}
            >
              {companySelected !== undefined
                ? companySelected.name
                : peopleSas?.NomeAbrevFantasia}
            </Typography>
            <Grid container>
              <Grid sm={6} md={6} lg={6} item>
                <Card sx={{}}>
                  <CardActionArea onClick={() => gotoNewAvaliation()}>
                    <CardMedia
                      component="img"
                      image={btNovaAvaliacao}
                      alt="Nova Avaliação"
                    />
                  </CardActionArea>
                </Card>
              </Grid>
              <Grid sm={6} md={6} lg={6} item>
                <Card sx={{}}>
                  <CardActionArea onClick={() => toggleAvaliations()}>
                    <CardMedia
                      component="img"
                      image={btMinhasAvaliacoes}
                      alt="Minhas Avaliações"
                    />
                  </CardActionArea>
                </Card>
              </Grid>
            </Grid>
          </Box>
          <Element name="scroll-to-element" className="element">
            {showDiagnostics && (
              <React.Fragment>
                <Typography gutterBottom variant="h5" component="div">
                  Meus Diagnosticos
                </Typography>

                {formResponses !== undefined && (
                  <Responses
                    user={user}
                    formResponses={formResponses}
                  ></Responses>
                )}
              </React.Fragment>
            )}
          </Element>
        </Container>
      </Grid>
    </React.Fragment>
  );
};

export default CompanyPage;

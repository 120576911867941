import * as React from "react";
import {
  Box,
  Button,
  Card,
  CardContent,
  CardMedia,
  Container,
  CssBaseline,
  Grid,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Typography,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import { Campaign } from "../../types/campaign";
import { RouteComponentProps } from "react-router";
import { StaticContext } from "react-router";
import QRCode from "qrcode.react";
import parse from "html-react-parser";
import { getCampaign } from "../../services/Campaign";
import FiberManualRecordIcon from "@mui/icons-material/FiberManualRecord";
import history from "../../history";
import { Link as RouterLink } from "react-router-dom";
// import { useAuth } from "../../contexts/auth";
interface MatchParams {
  campaignUuid: string;
}
type LocationState = {
  campaign: Campaign;
  savedCampaign?: Campaign;
};
const useStyles = makeStyles((theme: any) => ({
  heroContent: {
    padding: theme.spacing(8, 0, 6),
  },
  labelText: {
    color: "#FFFFFF",
  },
  inputText: {
    color: "#FFFFFF",
    borderBottomColor: "#FFFFFF",
  },
  iconList: {
    fontSize: "10px",
    color: "#bfcd00",
    minWidth: "20px",
  },
  buttonStyle: {
    color: "#FFFFFF",
    boxShadow: "1px 5px #00c4ff",
  },

  cardCampaign: {
    minWidth: "80%",
    maxWidth: "100%",
    backgroundColor: "#073e7b",
    border: "5px solid #0044a6",
    outline: "2px solid #FFFFFF",
    borderRadius: "15px",
  },
  boxCardCampaign: {
    display: "flex",
    justifyContent: "center",
    p: 1,
    m: 1,
    bgcolor: "background.paper",
    borderRadius: 1,
  },
  boxQrCode: {
    backgroundColor: "#FFFFFF",
    padding: "25px",
    alignItems: "center",
    borderRadius: "15px",
    float: "left",
  },
}));
const { REACT_APP_API_BASE_URL } = process.env;
const CampaignPage = (
  props: RouteComponentProps<MatchParams, StaticContext, LocationState>
) => {
  const [campaign, setCampaign] = React.useState<Campaign>();
  const classes = useStyles();
  // const { signed } = useAuth();
  const baseUrl = window.location.protocol + "://" + window.location.host + "/";
  React.useEffect(() => {
    async function loadCampaign(campaignUuid) {
      const campaign = await getCampaign(campaignUuid);
      setCampaign(campaign);
    }
    if (props.location.state !== undefined) {
      setCampaign(props.location.state.campaign);
    } else {
      loadCampaign(props.match.params.campaignUuid);
    }
  }, [setCampaign, props]);
  return (
    <React.Fragment>
      <CssBaseline />
      <Container maxWidth="lg" component="main" className={classes.heroContent}>
      <Box sx={{margin: "10px"}}>
        <Button
          color="primary"
          style={{color: "#FFF"}}
          variant="contained"
          onClick={() => {
            history.goBack();
          }}
        >
          Voltar
        </Button>
        </Box>
        {campaign !== undefined && (
          <Grid item md={12} lg={12} xs={12}>
            <Box className={classes.boxCardCampaign}>
              <Card className={classes.cardCampaign}>
                {campaign.image !== "" && (
                  <CardMedia
                    component="img"
                    height="140"
                    image={REACT_APP_API_BASE_URL + "/media/" + campaign.image}
                  />
                )}
                <CardContent>
                  <Typography
                    gutterBottom
                    variant="h3"
                    component="h3"
                    align="center"
                    style={{ color: "#bfcd00" }}
                  >
                    {campaign.name}
                  </Typography>
                  <Typography variant="body2" color="text.secondary">
                    {campaign.description!==null?parse(campaign.description):''}
                  </Typography>
                  <Grid container>
                    <Grid item xs={12} sm={12} md={4} lg={4}>
                      {campaign.form.only_company === true && (
                        <Box sx={{ margin: "5px" }}>
                          <Typography
                            variant="caption"
                            color="text.dark"
                            component="div"
                            style={{
                              padding: "5px",
                              borderBottom: "2px solid #bfcd00",
                              paddingBottom: "2px",
                              marginBottom: "5px",
                            }}
                          >
                            Quem pode fazer esse diagnóstico:
                          </Typography>
                          <List dense>
                            <ListItem disablePadding>
                              <ListItemIcon className={classes.iconList}>
                                <FiberManualRecordIcon fontSize={"inherit"} />
                              </ListItemIcon>
                              <ListItemText primary="Microempresa" />
                            </ListItem>
                            <ListItem disablePadding>
                              <ListItemIcon className={classes.iconList}>
                                <FiberManualRecordIcon fontSize={"inherit"} />
                              </ListItemIcon>
                              <ListItemText primary="Empresas de Pequeno Porte" />
                            </ListItem>
                            <ListItem disablePadding>
                              <ListItemIcon className={classes.iconList}>
                                <FiberManualRecordIcon fontSize={"inherit"} />
                              </ListItemIcon>
                              <ListItemText primary="Micro Empreendedor Individual (MEI)" />
                            </ListItem>
                          </List>
                        </Box>
                      )}
                    </Grid>
                    <Grid
                      item
                      xs={12}
                      sm={12}
                      md={8}
                      lg={8}
                      style={{
                        paddingLeft: "6%",
                      }}
                    >
                      <Box className={classes.boxQrCode}>
                        <QRCode
                          value={baseUrl + `campaignPage/${campaign.uuid}`}
                          size={200}
                        />
                      </Box>
                    </Grid>

                    <Grid
                      item
                      xs={12}
                      sm={12}
                      md={12}
                      lg={12}
                      style={{ textAlign: "center", marginTop: "20px" }}
                    >
                      <Button
                        color="success"
                        variant="contained"
                        size="large"
                        className={classes.buttonStyle}
                        onClick={() => {
                          history.push(`/openSurvey/${campaign.uuid}`);
                        }}
                        component={RouterLink}
                        to={`/openSurvey/${campaign.uuid}`}
                      >
                        Acessar
                      </Button>
                    </Grid>
                  </Grid>
                </CardContent>
              </Card>
            </Box>
          </Grid>
        )}
      </Container>
    </React.Fragment>
  );
};

export default CampaignPage;

import React from "react";
import TextField from "./styled"

interface Props {
  name: string;
  label: string;
  default?: string;
  multiline?: boolean;
  maxRows?: Number;
  defaultValue?: any;
  error?: boolean;
  onChange?: (ev) => void;
}

export const Text = (props: Props) => {
  const getRows = (props) => {
    if (props.maxRows !== undefined) {
      return props.maxRows;
    } else return 1;
  };
  return (
      <TextField
        hiddenLabel
        value={props.defaultValue?props.defaultValue:''}
        onChange={props.onChange}
        id={props.name}
        name={props.name}
        label={props.label}
        fullWidth={true}
        multiline={props.multiline}
        maxRows={getRows(props)}
        rows={getRows(props)}
        variant="outlined"
        error={props.error}
      />
  );
};

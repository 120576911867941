import React from "react";
import MainLayout from "./layout/Main/index";
import { Router } from "react-router-dom";
import Routes from "./routes";
import history from "./history";
import { AuthProvider } from "./contexts/auth";
import { SurveyProvider } from "./contexts/survey";

function App() {
  return (
    <AuthProvider>
      <Router history={history}>
        <SurveyProvider>
          <MainLayout>
            <Routes />
          </MainLayout>
        </SurveyProvider>
      </Router>
    </AuthProvider>
  );
}

export default App;

import { Box } from "@mui/material";
import * as React from "react";
import ColoredBox from "../ColoredBox";

interface ITipsProps {
  
}
const tips = [
  {
    title: "Entre 0% e 20%",
    subtitle: "Muita calma nessa hora!",
    content:
      "Observamos que os processos da empresa não estão deﬁnidos e que os resultados são obtidos através de iniciativas informais e sem padronização.",
    color: "#ff471a",
  },
  {
    title: "Entre 20.1% a 40%",
    subtitle: "Atenção",
    content:
      "Não existem processos deﬁnidos, mas algumas áreas de gestão já possuem rotinas que podem gerar melhores resultados.",
    color: "#f6961e",
  },
  {
    title: "Entre 40,1% e 60%",
    subtitle: "Sua empresa está equilibrada, e isso é um bom sinal.",
    content:
      "Existem processos gerenciais já deﬁnidos, mas ainda não estão documentados. O alinhamento das equipes é fundamental para gerar os resultados esperados.",
    color: "#ecdb23",
  },
  {
    title: "Entre 60,1% e 80%",
    subtitle: "Você está no caminho certo!",
    content:
      "Os processos gerenciais são formais e aplicados de maneira coordenada em busca dos melhores resultados, mas ainda há espaço para crescimento.",
    color: "#aee228",
  },
  {
    title: "Entre 80,1% e 100%",
    subtitle: "Parabéns!",
    content:
      "Você é referência e fonte de inspiração para outras empresas. Os processos gerenciais estão padronizados e monitorados. Mantenha o foco no aperfeiçoamento contínuo para garantir o alcance dos resultados.",
    color: "#6ad72d",
  },
];
const TipsBox: React.FunctionComponent<ITipsProps> = (props) => {
  return (
    <React.Fragment>
      {tips.map((tip, i) => (
        <Box style={{ paddingLeft: "10%", paddingRight: "10%" }} key={i}>
          <ColoredBox
            title={tip.title}
            subtitle={tip.subtitle}
            content={tip.content}
            color={tip.color}
          />
        </Box>
      ))}
    </React.Fragment>
  );
};

export default TipsBox;

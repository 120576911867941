import {
  Box,
  Button,
  Card,
  CardContent,
  Grid,
  Typography,
} from "@mui/material";
import * as React from "react";
import CardStyled from "../../../components/CardStyled";
import { UserCompanies, UserExternal } from "../../../types/auth";

interface IClientCardProps {
  client: UserExternal;
  selectClient: (client: UserExternal, company?: UserCompanies) => void;
}

const ClientCard: React.FunctionComponent<IClientCardProps> = (props) => {
  return (
    <Grid item xs={12} md={12} lg={12}>
      <CardStyled
        title={props.client.user.first_name + " " + props.client.user.last_name}
      >
        <React.Fragment>
          <Box sx={{ display: "flex", justifyContent: "space-between" }}>
            <Typography variant="body1" component="div">
              {props.client.user.document.replace(
                /^(\d{3})(\d{3})(\d{3})(\d{2}).*/,
                "$1.$2.$3-$4"
              )}
            </Typography>
            <Box
              sx={{
                display: "flex",
                alignItems: "right",
                pl: 1,
                pb: 1,
              }}
            >
              <Button
                style={{}}
                variant="contained"
                color="primary"
                aria-label="add"
                onClick={(ev) => {
                  props.selectClient(props.client);
                }}
              >
                Acessar Detalhes
              </Button>
            </Box>
          </Box>
          {props.client.companies.length > 0 && (
            <React.Fragment>
              <Typography variant="h5" component="h5">
                Empresas
              </Typography>
              {props.client.companies.map((company, j) => (
                <Card sx={{ marginBottom: "10px" }} key={j}>
                  <CardContent
                    sx={{
                      display: "flex",
                      justifyContent: "space-between",
                    }}
                  >
                    <Typography variant="body2" component="div">
                      {company.name} -{" "}
                      {company.document.replace(
                        /^(\d{2})(\d{3})(\d{3})(\d{4})(\d{2}).*/,
                        "$1.$2.$3/$4-$5"
                      )}
                    </Typography>
                    <Button
                      style={{ marginLeft: "5px", float: "right" }}
                      variant="contained"
                      color="primary"
                      aria-label="add"
                      onClick={(ev) => {
                        props.selectClient(props.client, company);
                      }}
                    >
                      Acessar Detalhes
                    </Button>
                  </CardContent>
                </Card>
              ))}
            </React.Fragment>
          )}
        </React.Fragment>
      </CardStyled>
    </Grid>
  );
};

export default ClientCard;

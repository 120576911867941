/* eslint-disable @typescript-eslint/no-unused-vars */
import api from "./api";
import { LoginResponse } from "../types/auth";

async function signInApp(document: string, birth_date: string) {
  const userAuth = { document: document, birth_date: birth_date };
  const response: LoginResponse = await api.post("api/login", userAuth);
  if (response.data === undefined) {
    return response;
  } else {
    return response.data;
  }
}
async function signInConsultantApp(
  document: string,
  password: string,
  birth_date: string
) {
  const userAuth = { document: document,password: password, birth_date: birth_date };
  const response: LoginResponse = await api.post("api/login_consultant", userAuth);
  if (response.data === undefined) {
    return response;
  } else {
    return response.data;
  }
}
async function signUpApp(
  document: string,
  birth_date: string,
  email: string,
  phone: string
) {
  const userAuth = {
    document: document,
    birth_date: birth_date,
    email: email,
    phone: phone,
  };
  const response: LoginResponse = await api.post("api/login", userAuth);
  if (response.data === undefined) {
    return response;
  } else {
    return response.data;
  }
}

export { signInApp, signUpApp, signInConsultantApp };

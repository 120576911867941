import * as React from "react";
import {
  Button,
  Grid,
  TextField,
  Typography,
} from "@mui/material";
import { SubmitHandler, useForm } from "react-hook-form";
import { CnpjMask, CpfMask } from "../../components/Field/InputMask";
import { Select } from "../../components/Field/Select";
import { findUserByCpf, findUserByCnpj } from "../../services/ExternalUser";
import { UserCompanies, UserExternal } from "../../types/auth";
import ClientCard from "./components/client_card";

interface ISearchClientsProps {
  selectClient: (client: UserExternal, company?: UserCompanies) => void;
}

type FormValues = {
  document: string;
  document_pj: string;
  field_type: string;
};

const SearchClients: React.FunctionComponent<ISearchClientsProps> = (props) => {
  const { register, handleSubmit, setValue, getValues } = useForm<FormValues>();
  const [showCpf, setShowCpf] = React.useState(true);
  const [field_type, setFieldType] = React.useState("CPF");
  const [clients, setClients] = React.useState<UserExternal[]>([]);

  React.useEffect(() => {
    setValue("field_type", "CPF");
  }, [setValue]);

  const onSubmit: SubmitHandler<FormValues> = async (data) => {
    if (data.field_type === "CPF") {
      setClients(await findUserByCpf(data.document));
    } else {
      setClients(await findUserByCnpj(data.document_pj));
    }
    return false;
  };
  const handleChangeType = (event: any) => {
    setValue("field_type", event.target.value);
    setFieldType(event.target.value);
    console.log(getValues("field_type"));
    if (getValues("field_type") === "CPF") {
      setShowCpf(true);
    } else {
      setShowCpf(false);
    }
  };

  return (
    <React.Fragment>
      <form noValidate onSubmit={handleSubmit(onSubmit)}>
        <Grid container>
          <Grid item xs={12} md={12} lg={12}>
            <h3>Busca de Clientes</h3>
          </Grid>
          <Grid item xs={6} md={6} lg={6}>
            <TextField
              sx={{ display: showCpf === true ? "block" : "none" }}
              color="primary"
              margin="normal"
              required
              fullWidth
              id="outlined-email-input"
              label="CPF"
              {...register("document")}
              InputProps={{
                inputComponent: CpfMask as any,
              }}
              autoFocus
            />
            <TextField
              sx={{ display: showCpf === true ? "none" : "block" }}
              color="primary"
              margin="normal"
              required
              fullWidth
              id="outlined-email-input"
              label="CNPJ"
              {...register("document_pj")}
              InputProps={{
                inputComponent: CnpjMask as any,
              }}
              autoFocus
            />
          </Grid>
          <Grid item xs={4} md={4} lg={4} sx={{ padding: "15px" }}>
            <Select
              label="Tipo de Documento"
              {...register("field_type")}
              onChange={handleChangeType}
              default={field_type}
              options={[
                { label: "CPF", value: "CPF" },
                { label: "CNPJ", value: "CNPJ" },
              ]}
            />
          </Grid>
          <Grid item xs={2} md={2} lg={2} sx={{ padding: "15px" }}>
            <Button
              type="submit"
              fullWidth
              variant="contained"
              color="primary"
              sx={{ padding: "15px" }}
            >
              Buscar
            </Button>
          </Grid>
        </Grid>
      </form>
      <Grid container>
        {clients !== undefined && (
          <React.Fragment>
            <Typography variant="h3" component="h3">
              {clients.length > 1 ? "Clientes" : "Cliente"}
            </Typography>
            {clients.map((client, i) => (
              <ClientCard
                selectClient={props.selectClient}
                client={client}
                key={i}
              />
            ))}
          </React.Fragment>
        )}
      </Grid>
    </React.Fragment>
  );
};

export default SearchClients;

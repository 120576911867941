import React, { useEffect } from "react";
// import Survey from "./index";
import {
  getCampaign,
  getFormResponses,
  responsesFormResponse,
} from "../../services/Campaign";
import { Campaign, ResponseForm } from "../../types/campaign";
import history from "../../history";
import { RouteComponentProps, StaticContext } from "react-router";
import { CssBaseline } from "@mui/material";

interface MatchParams {
  responseUuid: string;
}
export const LoadForm = (
  props: RouteComponentProps<MatchParams, StaticContext>
) => {
  const [formData, setFormData] = React.useState<object>();
  const [responseForm, setResponseForm] = React.useState<ResponseForm>();
  const [campaign, setCampaign] = React.useState<Campaign>();

  useEffect(() => {
    const loadCampain = async (uuid) => {
      if (uuid !== undefined) {
        const responseCampaing: Campaign = await getCampaign(uuid);
        setCampaign(responseCampaing);
      }
    };

    const loadFormResponses = async (uuid) => {
      let responseForm = await getFormResponses(uuid);
      let responses = await responsesFormResponse(uuid);

      setFormData(responses);
      // formik.setValues(responses);
      setResponseForm(responseForm);
      loadCampain(responseForm.campaign.uuid);
    };
    loadFormResponses(props.match.params.responseUuid);

    if (campaign !== undefined && formData !== undefined) {
      history.push(`/survey/${campaign.uuid}`, {
        Campaign: campaign,
        formData: formData,
        ResponseForm: responseForm,
      });
    }
  }, [props, campaign, responseForm, formData]);

  return (
    <React.Fragment>
      <CssBaseline />
    </React.Fragment>
  );
};

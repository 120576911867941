import React, { useEffect } from "react";
import { makeStyles } from "@mui/styles";
import { createTheme, Container, CssBaseline, Grid, Button } from "@mui/material";
import { listCampaigns } from "../../services/Campaign";
import { Campaign } from "../../types/campaign";
import history from "../../history";
import { Link as RouterLink } from "react-router-dom";
import CampainCardAction from "../../components/CampaignCardAction";

const useStyles = makeStyles((theme: any) => ({
  heroContent: {
    padding: theme.spacing(8, 0, 6),
  },
  buttonStyle: {
    color: "#FFFFFF",
    boxShadow: "1px 5px #00c4ff",
  },
}));
const theme = createTheme();
theme.palette.background = {
  default: "#ffffff",
  paper: "#FFFFFF",
};
interface ICampaignListProps {}

const CampaignListPublic: React.FunctionComponent<ICampaignListProps> = (
  props
) => {
  const classes = useStyles();
  const [campaigns, setCampaings] = React.useState<Campaign[]>();
  useEffect(() => {
    const loadCampains = async () => {
      let campaingsLs = await listCampaigns(true, true);
      setCampaings(campaingsLs);
    };
    if (campaigns === undefined) {
      loadCampains();
    }
  }, [campaigns]);

  const openForm = (ev, campaign) => {
    console.log(ev, campaign);
    history.push(`campaignPage/${campaign.uuid}`, { campaign: campaign });
  };

  const getActions = (campaign) => {
    return (
      <React.Fragment>
        <Button
          className={classes.buttonStyle}
          color="success"
          variant="contained"
          onClick={() => {
            history.push(`/openSurvey/${campaign.uuid}`);
          }}
          component={RouterLink}
          to={`/openSurvey/${campaign.uuid}`}
        >
          Acessar
        </Button>
        <Button
          style={{ marginLeft: "5px" }}
          className={classes.buttonStyle}
          variant="contained"
          color="primary"
          aria-label="add"
          onClick={(ev) => {
            openForm(ev, campaign)
          }}
        >
          Mais detalhes
        </Button>
      </React.Fragment>
    );
  };

  return (
    <React.Fragment>
      <CssBaseline />

      {/* Hero unit */}
      <Container maxWidth="lg" component="main" className={classes.heroContent}>
        <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
          {campaigns?.map((campaign: Campaign, i) => (
             <CampainCardAction
             key={i}
             campaign={campaign}
             actionComponent={getActions(campaign)}
           />
          
          ))}
        </Grid>
      </Container>
      {/* End hero unit */}
    </React.Fragment>
  );
};

export default CampaignListPublic;

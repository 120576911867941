/* eslint-disable @typescript-eslint/no-unused-vars */
import api from "./api";
import { LgpdTerm } from "../types/lgpd";
import { AxiosResponse } from "axios";

const getLgpd = async () => {
  const response: AxiosResponse = await api.get("api/lgpd_term/last");
  const termLgpd: LgpdTerm = response.data;
  return termLgpd;
};

export { getLgpd };

import React from "react";
import { Link } from "@mui/material";
import ConfirmDialog, { confirmDialog } from "../../components/ConfirmDialog";
import { getLgpd } from "../../services/LgpdTerm";

const LgpdDialog: React.FC = () => {
  const openLgpd = async (ev) => {
    ev.preventDefault();
    const lgpd = await getLgpd();
    confirmDialog(lgpd.term, "OK", () => {}, lgpd.title, undefined);
  };
  return (
    <div>
      <Link color="primary" href="#" onClick={openLgpd}>
        Politica de privacidade
      </Link>
      <ConfirmDialog />
    </div>
  );
};

export default LgpdDialog;

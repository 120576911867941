import * as React from "react";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import logo from "../../assets/img/logoFundoTrans.png";
import history from "../../history";
import { Link as RouterLink } from "react-router-dom";
import { useAuth } from "../../contexts/auth";
import { Link, Menu, MenuItem } from "@mui/material";
import AvatarHead from "../../components/AvatarHead/index";

export default function ButtonAppBar() {
  const { signed, user, is_consultant, Logout } = useAuth();
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);

  const handleLogout = (ev) => {
    ev.preventDefault();
    setAnchorEl(null);
    Logout();
  };
  const handleMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const menuAvatar = () => {
    return (
      <React.Fragment>
        <Button
          aria-label="account of current user"
          aria-controls="menu-appbar"
          aria-haspopup="true"
          onClick={handleMenu}
          color="inherit"
        >
          <AvatarHead name={user?.name} />
        </Button>
        <Menu
          id="menu-appbar"
          anchorEl={anchorEl}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "right",
          }}
          keepMounted
          transformOrigin={{
            vertical: "top",
            horizontal: "right",
          }}
          open={Boolean(anchorEl)}
          onClose={handleClose}
        >
          <MenuItem onClick={handleLogout}>Sair</MenuItem>
        </Menu>
      </React.Fragment>
    );
  };

  const menuDeskLogged = (
    <React.Fragment>
      {is_consultant === true && (
        <Button
          onClick={() => history.push("/companies")}
          component={RouterLink}
          to="/consultant_panel"
          style={{ color: "#0444a4" }}
        >
          Painel Consultor
        </Button>
      )}
      {is_consultant === false && user?.is_consultant===true && (
        <Button
          onClick={() => history.push("/consultant_login")}
          component={RouterLink}
          to="/consultant_login"
          style={{ color: "#0444a4" }}
        >
          Login Consultor
        </Button>
      )}
      <Button
        onClick={() => history.push("/companies")}
        component={RouterLink}
        to="/companies"
        style={{ color: "#0444a4" }}
      >
        Painel
      </Button>
      {menuAvatar()}
    </React.Fragment>
  );

  const menuDesk = (
    <React.Fragment>
      <Button
        onClick={() => history.push("/")}
        component={RouterLink}
        to="/"
        style={{ color: "#0444a4" }}
      >
        Inicio
      </Button>
      <Button
        onClick={() => history.push("/login")}
        component={RouterLink}
        to="/login"
        style={{ color: "#0444a4" }}
      >
        Login
      </Button>
    </React.Fragment>
  );
  // const menuLoggedItens = [
  //   {
  //     title: "Avaliações",
  //     url: "/landing",
  //     component: null,
  //   },
  //   {
  //     title: "",
  //     url: null,
  //     component: menuAvatar(),
  //   },
  // ];

  return (
    <Box sx={{ flexGrow: 1 }}>
      <AppBar position="static" style={{ background: "#FFFFFF" }}>
        <Toolbar>
          <Typography variant="h6" component="div" sx={{ flexGrow: 1 }}>
            <Link
              onClick={() => history.push("/")}
              component={RouterLink}
              to="/"
            >
              <img src={logo} alt={"logo"} />
            </Link>
          </Typography>
          <nav>
            {signed === false ? (
              <React.Fragment>{menuDesk}</React.Fragment>
            ) : (
              <React.Fragment>{menuDeskLogged}</React.Fragment>
            )}
          </nav>
        </Toolbar>
      </AppBar>
    </Box>
  );
}

/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useEffect } from "react";
import { makeStyles } from "@mui/styles";
import {
  Button,
  Container,
  CssBaseline,
  Step,
  StepLabel,
  Stepper,
  Typography,
} from "@mui/material";
import { Campaign, PageForm, ResponseForm } from "../../types/campaign";
import {
  getCampaign,
  loadFullPages,
  sendResponseFormAnswers,
  updateFormResponse,
} from "../../services/Campaign";
import { Box } from "@mui/system";
import { useFormik } from "formik";
import history from "../../history";
import { Location } from "history";
import { StaticContext } from "react-router";
import { RouteComponentProps } from "react-router-dom";
import { Questions } from "./questions";
import Progress from "./progress";
import { useAuth } from "../../contexts/auth";
import { getDiagnostic } from "../../services/DiagnosticService";
import * as Yup from "yup";
import Swal from "sweetalert2";
import "@sweetalert2/theme-material-ui";

interface MatchParams {
  uuid: string;
  responseUuid: string;
}
type LocationState = {
  from: Location;
  Campaign: Campaign;
  formData?: object;
  ResponseForm?: ResponseForm;
};
const useStyles = makeStyles((theme: any) => ({
  heroContent: {
    padding: theme.spacing(8, 0, 6),
  },
  labelText: {
    color: "#FFFFFF",
  },
  inputText: {
    color: "#FFFFFF",
    borderBottomColor: "#FFFFFF",
  },
  icon: {
    fill: "#29b729",
  },
  textCompleted: {
    color: "#008800 !important",
    fill: "green",
    // fontWeight: "normal !important",
  },
}));

const Survey = (
  props: RouteComponentProps<MatchParams, StaticContext, LocationState>
) => {
  const [campaign, setCampaign] = React.useState<Campaign | undefined>();
  const [pages, setPages] = React.useState<PageForm[]>();
  const [currentPage, setCurrentPages] = React.useState<PageForm>();

  const [steps, setSteps] = React.useState<string[]>();
  const [questions, setQuestions] = React.useState<string[]>([]);
  const [activeStep, setActiveStep] = React.useState(0);
  const [formData, setFormData] = React.useState<object>(
    props.location.state.formData ? props.location.state.formData : {}
  );
  const [formResponse, setFormResponse] = React.useState<ResponseForm | null>(
    props.location.state.ResponseForm ? props.location.state.ResponseForm : null
  );
  const [progress, setProgress] = React.useState(1);
  const { signed } = useAuth();
  const [validationSchemas, setValidationSchemas] = React.useState<object>();
  const [currentValidationSchema, setCurrentValidationSchema] =
    React.useState<object>();

  useEffect(() => {
    const loadPages = async (FormUuid) => {
      if (pages === undefined) {
        const respPages = await loadFullPages(FormUuid);
        const mapedPage = respPages.map((page) => {
          return page.theme.name;
        });
        setCurrentPages(respPages[0]);
        setPages(respPages);
        setSteps(mapedPage);
        if (questions.length === 0) {
          let questionsList: string[] = [];
          let schema = {};
          respPages.forEach((pages, i) => {
            let questionsValidations = {};
            pages.page_element.forEach((pageElement, key) => {
              if (pageElement.element_type.name === "Pergunta") {
                pageElement.element_question.forEach((elQs) => {
                  if (elQs !== undefined && elQs.uuid) {
                    questionsList.push(elQs.uuid);
                    if (
                      elQs.question.question_type.slug !==
                      "caixa-de-selecao-para-mutiplas-opcoes"
                    ) {
                      questionsValidations[elQs.uuid] = Yup.string().required();
                    }
                  }
                });
              }
            });
            schema[i] = Yup.object().shape(questionsValidations);
          });
          setValidationSchemas(schema);
          setCurrentValidationSchema(schema[0]);
          setQuestions(questionsList);
        }
      }
    };

    const loadCampain = async (uuid) => {
      if (uuid !== undefined) {
        const responseCampaing: Campaign = await getCampaign(uuid);
        setCampaign(responseCampaing);
      }
      // console.log(responseCampaing);
    };

    if (
      props.location.state !== undefined &&
      props.location.state.Campaign !== undefined
    ) {
      setCampaign(props.location.state.Campaign);
    } else if (campaign === undefined) {
      loadCampain(props.match.params.uuid);
    }

    if (campaign !== undefined) {
      loadPages(campaign.form.uuid);
    }

    // if (formResponse == null) {
    //   loadFormResponse(user);
    // }
    // }
  }, [
    campaign,
    pages,
    currentPage,
    formResponse,
    formData,
    steps,
    questions,
    props,
  ]);

  /** Funções do WIZARD */
  function totalSteps(): number {
    if (steps !== undefined) {
      return steps?.length - 1;
    } else {
      return 0;
    }
  }

  const handleNext = async (ev, pages) => {
    ev.preventDefault();

    let formikValues = formik.values;
    // const validate = await formik.validateForm();
    if (await checkValidate()) {
      // console.log(formData)
      let data = { ...formData, ...formikValues };
      setFormData(data);
      sendDataToBack(data);
      await updateFormResponse(formResponse, progress);
      const nextStep = activeStep + 1;
      if (pages[nextStep]) {
        if (validationSchemas) {
          setCurrentValidationSchema(validationSchemas[nextStep]);
        }
        setCurrentPages(pages[nextStep]);
        setActiveStep((prevActiveStep) => prevActiveStep + 1);
      }
    }
  };

  const handleBack = (pages) => {
    let formikValues = formik.values;
    // console.log(formData)
    let data = { ...formData, ...formikValues };
    setFormData(data);
    sendDataToBack(data);
    const nextStep = activeStep - 1;
    setCurrentPages(pages[nextStep]);
    if (validationSchemas) {
      setCurrentValidationSchema(validationSchemas[nextStep]);
    }
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const updateProgress = async (perc, totalQuestions, totalResponses) => {
    setProgress(perc);
    if (formResponse != null && perc !== null) {
      let formResponseVar = formResponse;
      formResponseVar.progress = perc;

      setFormResponse(formResponseVar);
    }
  };

  const sendDataToBack = async (values) => {
    if (formResponse != null) {
      let responseAnswers = await sendResponseFormAnswers(
        formResponse.uuid,
        values
      );
      return responseAnswers;
    }
    return false;
  };

  const formik = useFormik({
    initialValues: formData,
    validationSchema: currentValidationSchema,
    // enableReinitialize: true,
    onSubmit: async (values) => {
      if (formResponse != null && await checkValidate()) {
        let responseAnswers = await sendResponseFormAnswers(
          formResponse.uuid,
          values
        );
        if (progress > 99) {
          if (formResponse != null) {
            let formResponseVar = formResponse;
            formResponseVar.is_completed = true;
            formResponseVar.progress = progress;
            setFormResponse(formResponseVar);
            await updateFormResponse(formResponseVar, progress);
          }
        }
        if (responseAnswers.error === false) {
          if (signed === true) {
            if (progress > 99) {
              await getDiagnostic(formResponse.uuid); //Cria o registro do diagnóstico para contabilizar no sistema
              if (
                campaign?.form.confirmation_message_mode !== "sem_devolutiva"
              ) {
                history.push(`/diagnostic/${formResponse.uuid}`);
              } else {
                //Entra aqui caso o diagnostico não tenha devolutiva
                history.push(`/companies`);
              }
            } else {
              history.push(`/companies`);
            }
          } else {
            history.push("/");
          }
        }
      }
    },
  });
  /**Fim funções do WIZARD */
  const checkValidate = async () => {
    const validate = await formik.validateForm();
    if (Object.keys(validate).length === 0) {
      return true;
    } else {
      Swal.fire({
        title: "Preencha todos os campos, para continuar.",
        icon: "warning",
        confirmButtonText: "OK",
        timer: 2000,
        timerProgressBar: true,
      });
      return false;
    }
  };
  const classes = useStyles();
  return (
    <React.Fragment>
      <CssBaseline />
      {pages !== undefined && currentPage !== undefined ? (
        <Container
          maxWidth="lg"
          component="main"
          className={classes.heroContent}
        >
          <Typography variant="h3" component={"div"}>
            {campaign?.form.name}
          </Typography>
          {questions !== undefined ? (
            <Progress
              formik={formik}
              questions={questions}
              responses={formData}
              progressUpdateCallback={updateProgress}
            />
          ) : (
            <></>
          )}
          <Stepper activeStep={activeStep} style={{ marginTop: 30 }}>
            {steps?.map((label) => {
              const stepProps: { completed?: boolean } = {};
              const labelProps: {
                optional?: React.ReactNode;
                classes: object;
                StepIconProps?: object;
              } = {
                classes: {
                  // active: classes.icon,

                  completed: classes.textCompleted,
                },
                StepIconProps: {
                  classes: {
                    completed: classes.icon,
                    // label: classes.text,
                  },
                },
              };
              return (
                <Step key={label} {...stepProps}>
                  <StepLabel {...labelProps}>{label}</StepLabel>
                </Step>
              );
            })}
          </Stepper>
          {activeStep === steps?.length ? (
            <React.Fragment></React.Fragment>
          ) : (
            <React.Fragment>
              <form id="Form" onSubmit={formik.handleSubmit}>
                {pages[activeStep].page_element.map((pageElement, key) => {
                  if (pageElement.element_type.name === "Pergunta") {
                    return (
                      <Questions
                        key={key}
                        formik={formik}
                        questions={pageElement.element_question}
                      />
                    );
                  }
                  return <></>;
                })}

                <Box sx={{ display: "flex", flexDirection: "row", pt: 2 }}>
                  <Button
                    color="inherit"
                    disabled={activeStep === 0}
                    onClick={() => handleBack(pages)}
                    sx={{ mr: 1 }}
                  >
                    Voltar
                  </Button>
                  <Box sx={{ flex: "1 1 auto" }} />
                  {activeStep === totalSteps() ? (
                    <Button type="submit">Concluir</Button>
                  ) : (
                    <Button onClick={(ev) => handleNext(ev, pages)}>
                      Próximo
                    </Button>
                  )}
                </Box>
              </form>
            </React.Fragment>
          )}
        </Container>
      ) : (
        <div>Carregando...</div>
      )}
    </React.Fragment>
  );
};
export default Survey;

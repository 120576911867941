import styled from "styled-components";
import { TextField } from "@mui/material";
import InputMask from "react-input-mask";

const Text = styled(TextField)``;

const Mask = styled(InputMask)`
  ${Text}
  padding: 16.5px 14px !important;
  ${(props: unknown | any) =>
    props.error &&
    `
      
    `}
`;

const Fields = {
  Text,
  Mask,
};

export default Fields;

/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useEffect } from "react";
import { makeStyles } from "@mui/styles";
import {
  Button,
  Container,
  CssBaseline,
  Grid,
  Typography,
} from "@mui/material";
import { Campaign } from "../../types/campaign";
import { listCampaigns } from "../../services/Campaign";
import history from "../../history";
import { RouteComponentProps, StaticContext } from "react-router";
import { useAuth } from "../../contexts/auth";
import CampainCardAction from "../../components/CampaignCardAction";
import { useSurvey } from "../../contexts/survey";

import { Link as RouterLink } from "react-router-dom";
import { UserExternal } from "../../types/auth";
import {
  loadFormResponsesCompany,
  openForm,
  showAlertNotExists,
  splitCampaign,
} from "./utils/utils";
import { Company } from "../../types/Company";

const useStyles = makeStyles((theme: any) => ({
  heroContent: {
    padding: theme.spacing(8, 0, 6),
  },
  labelText: {
    color: "#FFFFFF",
  },
  inputText: {
    color: "#FFFFFF",
    borderBottomColor: "#FFFFFF",
  },
  buttonStyle: {
    color: "#FFFFFF",
    boxShadow: "1px 5px #00c4ff",
  },
}));

type PageProps = { user: UserExternal } & RouteComponentProps<
  {
    campaignUuid: string;
    companyUuid?: string;
  },
  StaticContext,
  { user?: UserExternal; company?: Company }
>;

const CampaignsSelect: React.FC<PageProps> = (props) => {
  const classes = useStyles();
  const [campaigns, setCampaigns] = React.useState<Campaign[] | null>(null);
  const [campaignsUsed, setCampaignsUsed] = React.useState<Campaign[] | null>(
    null
  );
  const [companyUuid, setCompanyUuid] = React.useState<string | undefined>(
    props.match.params.companyUuid
  );
  const [selectedUser, setSelectedUser] = React.useState<
    UserExternal | undefined
  >(props.location.state?.user);
  const [selectedCompany, setSelectedCompany] = React.useState<
    Company | undefined
  >(props.location.state?.company);
  const { savedCampaign, ClearCampaign } = useSurvey();
  const [isConsulant, setIsConsultant] = React.useState<boolean>(
    props.location.state?.user !== undefined ? true : false
  );

  const { user } = useAuth();

  useEffect(() => {
    async function setCampaign(companyUuid) {
      let isCompany = false;
      if (companyUuid !== "") {
        isCompany = true;
      }
      const lstCampaigns = await listCampaigns(
        isCompany,
        null,
        undefined,
        null,
        isConsulant
      );
      const responses = await loadFormResponsesCompany(
        companyUuid,
        selectedUser
      );
      const campaignsSplited = splitCampaign(lstCampaigns, responses);

      if (lstCampaigns.length === 0) {
        showAlertNotExists(isCompany);
      }
      setCampaigns(campaignsSplited.available);
      setCampaignsUsed(campaignsSplited.used);
    }
    if (props.location.state?.user !== undefined) {
      setSelectedUser(props.location.state.user);
      setSelectedCompany(props.location.state.company);
    } else {
      setSelectedUser(user?.external_user);
    }

    if (
      props?.match !== undefined &&
      props.match.params.companyUuid !== undefined
    ) {
      //Verifica se a companyUuid foi informada na barra de titulos
      setCampaign(props.match.params.companyUuid);
      setCompanyUuid(props.match.params.companyUuid);
    } else {
      setCampaign("");
    }
  }, [props, user, companyUuid, savedCampaign, selectedUser, isConsulant]);

  if (
    props?.match !== undefined &&
    props.match.params.campaignUuid !== undefined &&
    savedCampaign !== undefined
  ) {
    const campaign = savedCampaign;
    ClearCampaign();
    if (campaign !== null && user !== null) {
      openForm(
        null,
        campaign,
        user.external_user,
        companyUuid === undefined ? "" : companyUuid,
        isConsulant === true ? user.external_user.uuid : ""
      );
    }
  }

  const getButtons = (campaign) => {
    let url = `/openSurvey/${campaign.uuid}`;
    if (user !== null) {
      url = `#`;
    }
    return (
      <Button
        className={classes.buttonStyle}
        color="success"
        variant="contained"
        onClick={(ev) => {
          if (user !== null) {
            openForm(
              ev,
              campaign,
              user.external_user,
              companyUuid === undefined ? "" : companyUuid,
              isConsulant === true ? user.external_user.uuid : ""
            );
          } else {
            history.push(url);
          }
        }}
        component={RouterLink}
        to={url}
      >
        Acessar
      </Button>
    );
  };

  const getUsedButtons = () => {
    return (
      <Button
        className={classes.buttonStyle}
        color="success"
        disabled
        variant="contained"
        component={RouterLink}
        to="#"
      >
        Já Respondida
      </Button>
    );
  };

  return (
    <React.Fragment>
      <CssBaseline />
      <Container maxWidth="lg" component="main" className={classes.heroContent}>
        <Grid container spacing={2}>
          {isConsulant && (
            <Grid item>
              <Typography gutterBottom variant="body2" component="div">
                Respondendo formulário por:
              </Typography>
              <Typography
                gutterBottom
                variant="h5"
                component="div"
                style={{ color: "#bfcd00" }}
              >
                Cliente: {selectedUser?.user.first_name}
              </Typography>
              {selectedCompany !== undefined && (
                <Typography
                  gutterBottom
                  variant="h5"
                  component="div"
                  style={{ color: "#bfcd00" }}
                >
                  Empresa: {selectedCompany?.name}
                </Typography>
              )}
            </Grid>
          )}
          {campaigns?.map((campaign: Campaign, i) => (
            <React.Fragment key={i}>
              <CampainCardAction
                campaign={campaign}
                actionComponent={getButtons(campaign)}
              />
            </React.Fragment>
          ))}
          {campaignsUsed?.map((campaign: Campaign, i) => (
            <React.Fragment key={i}>
              <CampainCardAction
                campaign={campaign}
                actionComponent={getUsedButtons()}
              />
            </React.Fragment>
          ))}
        </Grid>
      </Container>
    </React.Fragment>
  );
};
export default CampaignsSelect;

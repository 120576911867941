/* eslint-disable @typescript-eslint/no-unused-vars */
import * as React from "react";
import {
  Button,
  Container,
  CssBaseline,
  Grid,
  Typography,
} from "@mui/material";
import { ResponseForm } from "../../types/campaign";
import Responses from "../../components/Responses";
import { UserCompanies, UserExternal } from "../../types/auth";
import {
  listFormResponses,
  listFormResponsesCompany,
} from "../../services/Campaign";
import { RouteComponentProps, StaticContext } from "react-router";
import history from "../../history";
import { getUser } from "../../services/ExternalUser";
import { getCompany } from "../../services/CompanyService";

interface MatchParams {
  clientUuid: string;
  companyUuid?: string;
}
type LocationState = {
  user?: UserExternal;
  company?: UserCompanies;
};

const ClientDetails = (
  props: RouteComponentProps<MatchParams, StaticContext, LocationState>
) => {
  const [formResponses, setFormResponses] = React.useState<ResponseForm[]>();
  const [user, setUser] = React.useState<UserExternal | undefined>(
    props.location.state !== undefined ? props.location.state.user : undefined
  );
  const [company, setCompany] = React.useState<UserCompanies | undefined>(
    props.location.state !== undefined
      ? props.location.state.company
      : undefined
  );

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const loadClientResponses = async () => {
    if (user !== undefined && user.uuid !== undefined) {
      const lsFormResponses = await listFormResponses(user.uuid);
      setFormResponses(lsFormResponses);
    }
  };

  // eslint-disable-next-line react-hooks/exhaustive-deps
  async function loadFormResponsesCompany(companyUuid) {
    if (user !== undefined && user.uuid !== undefined) {
      if (companyUuid !== "") {
        const lsFormResponses = await listFormResponsesCompany(
          user.uuid,
          companyUuid
        );
        if (lsFormResponses !== undefined) {
          setFormResponses(lsFormResponses);
        }
      } else {
        if (user !== undefined && user.uuid !== undefined) {
          const lsFormResponses = await listFormResponses(user.uuid);
          if (lsFormResponses !== undefined) {
            setFormResponses(lsFormResponses);
          }
        }
      }
    }
  }
  React.useEffect(() => {
    const loadData = async () => {
      console.log(props.location.state);
      if (
        (props.location.state === undefined ||
          props.location.state.company === undefined) &&
        props.match.params.companyUuid === undefined
      ) {
        loadClientResponses();
      } else {
        loadFormResponsesCompany(props.match.params.companyUuid);
      }
    };
    const loadUser = async (clientUuid) => {
      let user = await getUser(clientUuid);
      if (user) {
        setUser(user);
      }
    };
    const loadCompany = async (companyUuid) => {
      let company = await getCompany(companyUuid);
      if (company) {
        setCompany(company);
      }
    };

    if (props.match.params.clientUuid && user === undefined) {
      loadUser(props.match.params.clientUuid);
    }
    if (props.match.params.companyUuid && company === undefined) {
      loadCompany(props.match.params.companyUuid);
    }
    if (formResponses === undefined) {
      loadData();
    }
  }, [
    loadClientResponses,
    loadFormResponsesCompany,
    user,
    company,
    formResponses,
    props,
  ]);

  return (
    <Container component="main" maxWidth="lg" style={{}}>
      <CssBaseline />
      <Grid container sx={{ padding: "15px" }}>
        <Grid item xs={8} md={8} lg={8}>
          <Typography
            gutterBottom
            variant="h5"
            component="div"
            style={{ color: "#bfcd00" }}
          >
            Cliente: {user?.user.first_name}
          </Typography>
          {company !== undefined && (
            <Typography
              gutterBottom
              variant="h5"
              component="div"
              style={{ color: "#bfcd00" }}
            >
              Empresa: {company?.name}
            </Typography>
          )}
        </Grid>
        <Grid item xs={4} md={4} lg={4} sx={{ padding: "text-align: right" }}>
          <Button
            style={{ marginLeft: "5px", float: "right", color: "#FFFFFF" }}
            variant="contained"
            color="primary"
            aria-label="add"
            onClick={(ev) => {
              history.push("/consultant_panel");
            }}
          >
            Voltar
          </Button>
          <Button
            style={{ marginLeft: "5px", float: "right", color: "#FFFFFF" }}
            variant="contained"
            color="success"
            aria-label="add"
            onClick={(ev) => {
              if (company !== undefined) {
                history.push(`/campainsSelect/` + company.uuid, { user: user, company: company });
              } else {
                history.push("/campainsSelect/", { user: user });
              }
            }}
          >
            Criar Diagnóstico
          </Button>
        </Grid>
        <Grid item xs={12} md={12} lg={12}>
          {formResponses !== undefined && user !== undefined && (
            <Responses
              user={user.user}
              formResponses={formResponses}
              is_consultant={true}
            ></Responses>
          )}
        </Grid>
      </Grid>
    </Container>
  );
};

export default ClientDetails;

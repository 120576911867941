/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { createContext, useState, useContext, useEffect } from "react";
import { Campaign } from "../types/campaign";

interface SurveyContextData {
  SaveCampaign(campaign: Campaign): void;
  ClearCampaign(): void;
  savedCampaign?: Campaign | null;
}
const SurveyContext = createContext<SurveyContextData>({} as SurveyContextData);

export const SurveyProvider: React.FC<any> = ({ children }) => {
  const [campaign, setCampaign] = useState<Campaign | null>(null);

  useEffect(() => {
    const campStr = sessionStorage.getItem("@App:campaign")
    if(campStr!==null){
      setCampaign(JSON.parse(campStr));
    }
    
  },[])
  const SaveCampaign = (campaign: Campaign) =>{
    setCampaign(campaign);
    sessionStorage.setItem("@App:campaign", JSON.stringify(campaign));
  }
  const ClearCampaign = () =>{
    setCampaign(null)
    sessionStorage.removeItem("@App:campaign")
  }
  return (
    <SurveyContext.Provider
      value={{
        SaveCampaign: SaveCampaign,
        ClearCampaign: ClearCampaign,
        savedCampaign: campaign,
      }}
    >
      {children}
    </SurveyContext.Provider>
  );
};
export function useSurvey() {
  const context = useContext(SurveyContext);

  return context;
}
import React from "react";
import { createTheme, ThemeProvider } from "@mui/material";
import Header from "../Header";

const theme = createTheme({
  palette: {
    mode: 'dark',
  },
  
});
theme.typography.h3 = {
  fontSize: "1.2rem",
  "@media (min-width:600px)": {
    fontSize: "1.5rem",
  },
  [theme.breakpoints.up("md")]: {
    fontSize: "2.4rem",
  },
};
theme.palette.background = {
  default: "#0444a4",
  paper: "#0444a4"
};
const Index = (props: {
  children:
    | boolean
    | React.ReactChild
    | React.ReactFragment
    | React.ReactPortal
    | null
    | undefined;
}) => {
  return (
    <ThemeProvider theme={theme}>
      <Header />
      {props.children}
    </ThemeProvider>
  );
};

export default Index;

import * as React from "react";
import { Box, Container, CssBaseline, Tab, Tabs } from "@mui/material";

import { UserExternal } from "../../types/auth";
// import ClientDetails from "./client_details";
import SearchClients from "./search_client";
import history from "../../history";
import { TabPanel, a11yProps } from "../../components/TabPanel";
import ClientsCart from "./clients_cart";
import UnfinishedResponses from "./unfinished_responses";

interface IConsultantPanelProps {}

const ConsultantPanel: React.FunctionComponent<IConsultantPanelProps> = (
  props
) => {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [selectedClient, setSelectedClient] =
    React.useState<UserExternal | null>();

  const selectClient = (client, company) => {
    console.log(client);
    console.log(company);
    let redirUrl = "/consultant_panel/client_detail/" + client.uuid;
    if (company !== undefined) {
      redirUrl += "/" + company.uuid;
    }
    history.push(redirUrl, {
      user: client,
      company: company,
    });
    // setSelectedClient(client);
  };
  const [value, setValue] = React.useState(0);

  const handleChangeTab = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };

  return (
    <Container component="main" maxWidth="lg" style={{}}>
      <CssBaseline />
      <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
        <Tabs
          value={value}
          onChange={handleChangeTab}
          aria-label="basic tabs example"
        >
          <Tab label="Busca de Clientes" {...a11yProps(0)} />
          <Tab label="Carteira de Clientes" {...a11yProps(1)} />
          <Tab label="Formulários não Finalizados" {...a11yProps(2)} />
        </Tabs>
      </Box>
      <TabPanel value={value} index={0}>
        <SearchClients selectClient={selectClient} />
      </TabPanel>
      <TabPanel value={value} index={1}>
        <ClientsCart selectClient={selectClient} />
      </TabPanel>
      <TabPanel value={value} index={2}>
        <UnfinishedResponses />
      </TabPanel>
    </Container>
  );
};

export default ConsultantPanel;

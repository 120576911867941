import React from "react";
import { makeStyles } from "@mui/styles";
import { Box, Container,  CssBaseline,  Link } from "@mui/material";
import botao from "../../assets/img/imagemCapa.png";
// import background from "../../assets/img/backgroundInicio.png";
import { Link as RouterLink } from "react-router-dom";
import history from "../../history";

interface IBannerProps {}



const useStyles = makeStyles((theme: any) => ({
  
  heroContent: {
    padding: theme.spacing(8, 0, 6),   
    minHeight:'776px',
    // background:  `url("${background}")  no-repeat top center fixed #0444a4`,
    // backgroundImage: `url("${background}")`,
    // backgroundPosition: "center center",
    // backgroundRepeat: "no-repeat",
    // backgroundSize: "cover",
    // backgroundAttachment: "fixed",
    height:"100%"
  }
}));


const Banner: React.FunctionComponent<IBannerProps> = (props) => {
  const classes = useStyles();
  return (
    <React.Fragment >
      <div  className={classes.heroContent} >
      {/* Hero unit */}
      <CssBaseline />
      <Container maxWidth="lg" component="main" >
          <Box style={{float: 'right'}}>
            <Link
              onClick={() => history.push("/campaigns")}
              component={RouterLink}
              to="/campaigns"
              style={{ color: "#0444a4", float: "right" }}
            >
             <img src={botao} alt="Faça o seu diagnóstico agora" style={{width: "100%"}} />
            </Link>
          </Box>
      </Container>
      </div>
    </React.Fragment>
  );
};

export default Banner;

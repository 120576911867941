import * as React from "react";
import {
  Button,
  Container,
  CssBaseline,
  TextField,
  Typography,
} from "@mui/material";
import { useAuth } from "../../contexts/auth";
import history from "../../history";
import { SubmitHandler, useForm } from "react-hook-form";
import { TypographyStyled } from "../LoginPage/styles";

export interface IConsultantLoginProps {}
type FormValues = {
  password: string;
};

export default function ConsultantLogin(props: IConsultantLoginProps) {
  const { user, LoginConsultant, is_consultant } = useAuth();
  const { register, handleSubmit } = useForm<FormValues>();
  const [showPassForm, setShowPassForm] = React.useState<boolean>(false);
  const [error, setError] = React.useState("");

  React.useEffect(() => {
    if ((user !== undefined || user !== null) && is_consultant === true) {
      history.push("/consultant_panel");
    }
    if (user === undefined || user === null) {
      history.push("/login");
    }
  }, [user, is_consultant]);
  const onSubmit: SubmitHandler<FormValues> = async (data) => {
    setError("");
    if (data.password.length > 3) {
      if (user !== undefined && user !== null) {
        let login = await LoginConsultant(user, data.password);
        if (login.error === true) {
          setError(
            "Falha na autenticação verifique sua senha e tente novamente"
          );
        } else {
          history.push("/companies");
        }
      }
    }
    return false;
  };
  const toogleShowForm = () => {
    setShowPassForm(!showPassForm);
  };
  return (
    <Container component="main" maxWidth="xs" style={{}}>
      <CssBaseline />

      <Typography
        variant="h3"
        component="div"
        style={{ marginTop: "30%", marginBottom: "10%" }}
      >
        Acesso do Consultor
      </Typography>
      {error && (
        <TypographyStyled variant="body1" color="error" paragraph={true}>
          {error}
        </TypographyStyled>
      )}
      <Typography
        variant="body1"
        component="div"
        style={{ marginTop: "20px", marginBottom: "10px" }}
      >
        Olá, {user?.name}
      </Typography>
      <Button
        fullWidth
        variant="contained"
        color="success"
        style={{ marginTop: "30px" }}
        onClick={() => {
          history.push("/companies");
        }}
      >
        Acessar como Cliente
      </Button>
      {!showPassForm && (
        <Button
          fullWidth
          variant="contained"
          color="primary"
          style={{ marginTop: "30px" }}
          onClick={() => {
            toogleShowForm();
          }}
        >
          Acessar como Consultor
        </Button>
      )}
      {showPassForm && (
        <React.Fragment>
          <Typography variant="body2" component="div">
            Informe sua senha para acessar o sistema:
          </Typography>
          <form noValidate onSubmit={handleSubmit(onSubmit)}>
            <TextField
              color="primary"
              margin="normal"
              required
              fullWidth
              id="outlined-email-input"
              label="Senha"
              type="password"
              {...register("password")}
              autoFocus
            />
            <Button
              type="submit"
              fullWidth
              variant="contained"
              color="primary"
              style={{ marginTop: "30px" }}
            >
              Autenticar
            </Button>
          </form>
        </React.Fragment>
      )}
    </Container>
  );
}

/* eslint-disable @typescript-eslint/no-unused-vars */
import * as React from "react";
import {
  Card,
  CardContent,
  CardHeader,
  Checkbox,
  Container,
  createTheme,
  CssBaseline,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  ThemeProvider,
  Typography,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import { RouteComponentProps, StaticContext } from "react-router";
import {
  getCampaign,
  getFormPages,
  getFormResponses,
  responsesFormResponse,
} from "../../services/Campaign";
import { Campaign, PageForm, ResponseForm } from "../../types/campaign";
import { format } from "date-fns/fp";
import { parseISO, isAfter, addDays } from "date-fns";

const useStyles = makeStyles((theme: any) => ({
  heroContent: {
    padding: theme.spacing(8, 0, 6),
  },
  labelText: {
    color: "#FFFFFF",
  },
  inputText: {
    color: "#FFFFFF",
    borderBottomColor: "#FFFFFF",
  },
}));

type MatchParams = {
  formResponseUuid: string;
};
type LocationState = {};
export default function SurveyResponses(
  props: RouteComponentProps<MatchParams, StaticContext, LocationState>
) {
  const [formResponse, setFormResponse] = React.useState<ResponseForm | null>();
  const [formData, setFormData] = React.useState<object>();
  const [campaign, setCampaign] = React.useState<Campaign>();
  const [pages, setPages] = React.useState<PageForm[]>();

  const loadCampain = async (uuid) => {
    if (uuid !== undefined) {
      const responseCampaing: Campaign = await getCampaign(uuid);
      setCampaign(responseCampaing);
      await loadPages(responseCampaing.form.uuid);
    }
  };
  const loadPages = async (FormUuid) => {
    if (pages === undefined) {
      const respPages = await getFormPages(FormUuid);
      setPages(respPages);
    }
  };

  const loadResponses = async (formResponseUUid) => {
    const formRespnse = await getFormResponses(formResponseUUid);
    let responses = await responsesFormResponse(formResponseUUid);

    setFormData(responses);
    if (formRespnse) {
      setFormResponse(formRespnse);
      loadCampain(formRespnse.campaign.uuid);
    }
  };
  if (formResponse === undefined) {
    setFormResponse(null);
    loadResponses(props.match.params.formResponseUuid);
  }
  const checkCheckedAlternative = (alternativeUuid, responses) => {
    if (Array.isArray(responses)) {
      const exists = responses.find((uuid) => {
        return Array.isArray(uuid) && uuid[0] === alternativeUuid;
      });

      return exists !== undefined;
    } else {
      return responses === alternativeUuid;
    }
  };
  const lightTheme = createTheme({ palette: { mode: "light" } });
  const classes = useStyles();
  return (
    <div>
      <CssBaseline />
      {formData !== undefined && (
        <Container
          maxWidth="lg"
          component="main"
          className={classes.heroContent}
        >
          <ThemeProvider theme={lightTheme}>
            <Card
              variant="elevation"
              sx={{ marginTop: "10px", marginBottom: "10px" }}
            >
              <CardContent>
                <Typography gutterBottom variant="h5" component="div">
                  {formResponse?.campaign.name}
                </Typography>
                <Typography variant="body2" color="text.secondary">
                  Iniciado :{" "}
                  {formResponse?.created &&
                    format("dd/MM/y", parseISO(formResponse.created))}
                  &nbsp;
                  {formResponse?.is_completed === true && (
                    <React.Fragment>
                      | Concluído :{" "}
                      {format("dd/MM/y", parseISO(formResponse.modified))}
                    </React.Fragment>
                  )}
                </Typography>
                <Typography variant="body2" color="text.secondary">
                  Respondido por: {formResponse?.external_users.user.first_name}{" "}
                  {formResponse?.external_users.user.last_name}
                </Typography>
                <Typography variant="body2" color="text.secondary">
                  Validade:
                  {formResponse?.validaty &&
                    format("dd/MM/y", parseISO(formResponse?.validaty))}
                </Typography>
              </CardContent>
            </Card>
            <Typography
              gutterBottom
              variant="h4"
              component="div"
              sx={{ textAlign: "left", margin: "20px" }}
            >
              Respostas
            </Typography>
            {pages?.map((page, k) => (
              <div key={k}>
                {page.page_element.map((pageElement) => (
                  <div key={pageElement.id}>
                    <Typography
                      gutterBottom
                      variant="h5"
                      component="div"
                      sx={{ textAlign: "center", margin: "20px" }}
                    >
                      {page.theme.name}
                    </Typography>
                    {pageElement.element_type.name === "Pergunta" && (
                      <div>
                        {pageElement.element_question.map((eleQuestion) => (
                          <Card
                            variant="elevation"
                            key={eleQuestion.id}
                            sx={{ marginTop: "10px", marginBottom: "10px" }}
                          >
                            <CardHeader
                              title={eleQuestion.question.name}
                              sx={{ borderBottom: "1px solid #0444a4" }}
                            />
                            <CardContent>
                              {eleQuestion.question.question_type
                                .has_offered_answer === true ? (
                                <List
                                  sx={{
                                    width: "100%",
                                    // maxWidth: 360,
                                  }}
                                >
                                  {eleQuestion.question_offered_answer.map(
                                    (ofAns) => (
                                      <ListItem key={ofAns.id} disablePadding>
                                        <ListItemButton role={undefined} dense>
                                          <ListItemIcon>
                                            <Checkbox
                                              edge="start"
                                              checked={checkCheckedAlternative(
                                                ofAns.uuid,
                                                formData[eleQuestion.uuid]
                                              )}
                                              tabIndex={-1}
                                              disableRipple
                                              inputProps={{
                                                "aria-labelledby": ofAns.uuid,
                                              }}
                                            />
                                          </ListItemIcon>
                                          <ListItemText
                                            id={ofAns.uuid}
                                            primary={ofAns.value}
                                          />
                                        </ListItemButton>
                                      </ListItem>
                                    )
                                  )}
                                </List>
                              ) : (
                                <div>{formData[eleQuestion.uuid]}</div>
                              )}
                            </CardContent>
                          </Card>
                        ))}
                      </div>
                    )}
                  </div>
                ))}
              </div>
            ))}
          </ThemeProvider>
        </Container>
      )}
    </div>
  );
}

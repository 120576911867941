import React, { useEffect } from "react";
import { Button, Container, CssBaseline } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { RouteComponentProps, StaticContext } from "react-router";
import {
    getDiagnostic,
    getDiagnosticAverange,
    getRecommendedSolutions,
} from "../../services/DiagnosticService";
import {
    AverangeDiagnosticResponse,
    DiagnosticResponse,
    ResponseDiagnosticSolutions,
    // ResponseDiagnosticSolutions,
} from "../../types/Diagnostic";
import { ResponseForm } from "../../types/campaign";
import { getFormResponses } from "../../services/Campaign";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import DiacnosticDocument from "../../components/DiagnosticDocument";
import downloadPdf from "dom-to-pdf-docker";

const useStyles = makeStyles((theme: any) => ({
    heroContent: {
        padding: theme.spacing(8, 0, 6),
    },
    labelText: {
        color: "#FFFFFF",
    },
    inputText: {
        color: "#FFFFFF",
        borderBottomColor: "#FFFFFF",
    },
}));

type MatchParams = {
    uuid: string;
};
type LocationState = {};
// const DiacnosticDocument = React.lazy(() => import( "../../components/DiagnosticDocument"));
const Diagnostic = (
    props: RouteComponentProps<MatchParams, StaticContext, LocationState>
) => {
    const [diagnostic, setDiagnostic] = React.useState<
        DiagnosticResponse | undefined | null
    >();
    const [averangeDiagnostic, setAverangeDiagnostic] = React.useState<
        AverangeDiagnosticResponse | undefined | null
    >();
    const [themes, setThemes] = React.useState<string[]>();
    const [formResponse, setFormResponse] = React.useState<
        ResponseForm | undefined | null
    >();
    const [solutions, setSolutions] = React.useState<
        ResponseDiagnosticSolutions | undefined | null
    >();

    useEffect(() => {
        const loadDiagnostic = async (formResponseUuid) => {
            const diagnostic = await getDiagnostic(formResponseUuid);
            setDiagnostic(diagnostic);
            setThemes(Object.keys(diagnostic.points));
        };
        const loadAverangeDiagnostic = async (companyUuid) => {
            const averangeDiagnosticResp = await getDiagnosticAverange(
                companyUuid
            );
            setAverangeDiagnostic(averangeDiagnosticResp);
        };
        const loadFormResponse = async (formResponseUuid) => {
            const formResponseRet = await getFormResponses(formResponseUuid);
            setFormResponse(formResponseRet);
        };
        const loadSolutions = async (formResponseUuid) => {
            const respSolutions = await getRecommendedSolutions(
                formResponseUuid
            );
            setSolutions(respSolutions);
        };
        if (diagnostic === undefined) {
            setDiagnostic(null);
            setSolutions(null);
            loadDiagnostic(props.match.params.uuid);
            loadSolutions(props.match.params.uuid);
        }

        if (formResponse === undefined) {
            setFormResponse(null);
            loadFormResponse(props.match.params.uuid);
        }
        if (
            formResponse !== undefined &&
            formResponse !== null &&
            formResponse.company.uuid !== undefined &&
            averangeDiagnostic === undefined
        ) {
            setAverangeDiagnostic(null);
            loadAverangeDiagnostic(formResponse.company.uuid);
        }
    }, [props, diagnostic, solutions, formResponse, averangeDiagnostic]);
    useEffect(() => {}, []);

    const generatePdf = async () => {
        if (formResponse) {
            const element = document.querySelector("#summary-report-container");
            const options = {
                filename: `${formResponse.uuid}.pdf`,
                overrideWidth: 1000,
            };

            await downloadPdf(element, options, (pdf) => {
                // callback function
                // pdf.output('dataurlstring');
                // pdf.save();
            });
        }
        return false;
    };

    const metodo = (diagn, averangeDia, them, formRes, solut) => {
        if (formRes.form.confirmation_message_mode === "devolutiva_empresas") {
            return (
                <DiacnosticDocument
                    diagnostic={diagn}
                    averangeDiagnostic={averangeDia}
                    themes={them}
                    formResponse={formRes}
                    solutions={solut}
                />
            );
        } else {
            return (
                <h3 style={{ textAlign: "center" }}>
                    Nenhuma Devolutiva foi selecionada
                </h3>
            );
        }
    };

    const lightTheme = createTheme({ palette: { mode: "light" } });
    const classes = useStyles();
    return (
        <React.Fragment>
            <CssBaseline />
            <div id="summary-report-container" style={{ width: "100%" }}>
                <Container
                    maxWidth="lg"
                    component="main"
                    className={classes.heroContent}
                >
                    <Button
                        onClick={generatePdf}
                        variant="contained"
                        color="success"
                        size="large"
                        style={{ color: "#FFFFFF" }}
                    >
                        Download do Diagnóstico
                    </Button>
                    <ThemeProvider theme={lightTheme}>
                        <React.Suspense fallback={<h1>Loading</h1>}>
                            {diagnostic && themes && formResponse && (
                                <React.Fragment>
                                    {metodo(
                                        diagnostic,
                                        averangeDiagnostic,
                                        themes,
                                        formResponse,
                                        solutions
                                    )}
                                </React.Fragment>
                            )}
                        </React.Suspense>
                    </ThemeProvider>
                </Container>
            </div>
        </React.Fragment>
    );
};

export default Diagnostic;

import * as React from "react";
import {
  Grid,
  Typography,
  Box,
  Button,
  Card,
  CardContent,
} from "@mui/material";
import { UserCompanies, UserExternal } from "../../../types/auth";

interface IPeopleCardProps {
  people: UserExternal;
  selectClient: (client: UserExternal, company?: UserCompanies) => void;
}

const PeopleCard: React.FunctionComponent<IPeopleCardProps> = (props) => {
  return (
    <Grid item xs={12} md={12} lg={12}>
      <Card sx={{ marginBottom: "10px" }}>
        <CardContent
          sx={{
            display: "flex",
            justifyContent: "space-between",
          }}
        >
          <Typography variant="body2" component="div">
            {props.people.user.first_name + " " + props.people.user.last_name} -{" "}
            {props.people.user.document.replace(
              /^(\d{3})(\d{3})(\d{3})(\d{2}).*/,
              "$1.$2.$3-$4"
            )}
          </Typography>
          <Box
            sx={{
              display: "flex",
              alignItems: "right",
              pl: 1,
              pb: 1,
            }}
          >
            <Button
              style={{}}
              variant="contained"
              color="primary"
              aria-label="add"
              onClick={(ev) => {
                props.selectClient(props.people);
              }}
            >
              Acessar Detalhes
            </Button>
          </Box>
        </CardContent>
      </Card>
    </Grid>
  );
};

export default PeopleCard;

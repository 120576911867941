import * as React from "react";
import { Paper, Box, Grid } from "@mui/material";

interface IColoredBoxProps {
  title: string;
  subtitle: string;
  content: string;
  color: string;
}

const ColoredBox: React.FunctionComponent<IColoredBoxProps> = (props) => {
  return (
    <React.Fragment>
      <Paper elevation={1} style={{ margin: "10px", minHeight: '150px' }}>
        <Grid container>
          <Grid item md={12} xs={12} lg={12} style={{backgroundColor: "#FFFFCC"}}>
            <Box
              style={{
                margin: "0px",
                padding: "5px 5px",
                color: "white",
                backgroundColor: props.color,
                width: "35%",
                borderRadius: "3px 10px 0px 0px",
                float: "left",
                textAlign: "left",
                fontSize: "20px", 
                fontWeight:"bold"
              }}
            >
              {props.title}
            </Box>
            <Box  style={{textAlign: "left", marginTop: '4px', padding: "5px 5px",  float: "left", color: props.color, fontSize:"15px", width: "65%"}}> 
                {props.subtitle}
            </Box>
          </Grid>
        </Grid>
        <Box
          style={{
            width: "100%",
            padding: '10px',
            textAlign: "justify"
          }}
        >
          {props.content}
        </Box>
      </Paper>
    </React.Fragment>
  );
};

export default ColoredBox;

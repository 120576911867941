import { Box } from "@mui/material";
import * as React from "react";
import {
  Bar,
  BarChart,
  CartesianGrid,
  LabelList,
  Legend,
  ResponsiveContainer,
  XAxis,
  YAxis,
} from "recharts";
import { AverangeDiagnosticResponse, DiagnosticResponse } from "../../types/Diagnostic";
import { useStyles } from "./styles";
import { getPercent, getPercentMarket,getFormatedNumber  } from "./utils";

interface IComparativeChartsProps {
    diagnostic: DiagnosticResponse | undefined;
    averangeDiagnostic: AverangeDiagnosticResponse | undefined;
    themes: string[];
}

const ComparativeCharts: React.FunctionComponent<IComparativeChartsProps> = (
  props
) => {
  const classes = useStyles();

  const [diagnostic] = React.useState(props.diagnostic);
  const [averangeDiagnostic] = React.useState(props.averangeDiagnostic);
  const [themes] = React.useState(props.themes)
//   console.log(diagnostic);
//   console.log(averangeDiagnostic);
//   console.log(themes);


  const getData = () => {
    let data: any = [];
    themes?.forEach((theme) => {
      let toPush = {
        name: `${theme}`,
        vc: getPercent(theme, diagnostic),
        mercado: getPercentMarket(theme, averangeDiagnostic),
        amt: 100,
      };
      data.push(toPush);
    });
    return data;
  };

  const renderCustomizedLabel = (props) => {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const { x, y, width, height, value } = props;
    const radius = 5;
    return (
      <g>
        <text
          x={x + width / 2}
          y={y - radius}
          fill="#028ac8"
          textAnchor="middle"
          dominantBaseline="middle"
          fontWeight="bold"
          fontSize="10px"
        >
          {getFormatedNumber(value)}%
        </text>
      </g>
    );
  };
  const renderMercadoLabel = (props) => {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const { x, y, width, height, value } = props;
    const radius = 17;
    return (
      <g>
        <text
          x={x + width / 2}
          y={y - radius}
          fill="#4dd230"
          textAnchor="middle"
          dominantBaseline="middle"
          fontWeight="bold"
          fontSize="10px"
        >
          {getFormatedNumber(value)}%
        </text>
      </g>
    );
  };
  return (
    <React.Fragment>
      <Box className={classes.boxSubTitle}>Comparativo com o mercado</Box>
      <Box className={classes.line} />
      <div style={{ width: "80%", height: 300, marginTop: "10px" }}>
        <ResponsiveContainer>
          <BarChart
            width={1000}
            height={250}
            data={getData()}
            margin={{
              top: 5,
              right: 30,
              left: 20,
              bottom: 5,
            }}
          >
            <CartesianGrid strokeDasharray="3 3" />
            <XAxis dataKey="name" />
            <YAxis type="number" name="%" unit="%" domain={[0, 100]} />
            <Legend />
            <Bar dataKey="vc" name="Sua Empresa" unit="%" fill="#028ac8">
              <LabelList dataKey="vc" content={renderCustomizedLabel} />
            </Bar>
            <Bar
              dataKey="mercado"
              unit="%"
              name="Média do mercado"
              fill="#25b11a"
            >
              <LabelList dataKey="mercado" content={renderMercadoLabel} />
            </Bar>
          </BarChart>
        </ResponsiveContainer>
      </div>
    </React.Fragment>
  );
};

export default ComparativeCharts;

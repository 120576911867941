import * as React from "react";
import { Grid, Typography } from "@mui/material";
import { ResponseForm } from "../../types/campaign";
import { useStyles } from "./styles";
import { format } from "date-fns/fp";
import { parseISO } from "date-fns";

interface ICompanyDataProps {
  formResponse: ResponseForm;
}

const CompanyData: React.FunctionComponent<ICompanyDataProps> = (props) => {
  const [formResponse] = React.useState(props.formResponse);
  const classes = useStyles();
  return (
    <React.Fragment>
      <Typography variant="h5" component="div" className={classes.centerTitle}>
        INFORMAÇÕES GERAIS
      </Typography>
      <Grid container style={{ width: "100%" }}>
        <Grid item sm={12} md={12} lg={12}>
          <Typography variant="h6" component="span" className={classes.textDL}>
            Respondido por:
          </Typography>
          <Typography variant="h6" component="span" className={classes.textDD}>
            {formResponse.external_users.user.first_name}{" "}
            {formResponse.external_users.user.last_name} -{" "}
            {formResponse.external_users.user.document}
          </Typography>
          <br />
          <Typography variant="h6" component="span" className={classes.textDL}>
            Nome da Empresa:
          </Typography>
          <Typography variant="h6" component="span" className={classes.textDD}>
            {formResponse.company.name}
          </Typography>
          <br />
          <Typography variant="h6" component="span" className={classes.textDL}>
            CNPJ:
          </Typography>
          <Typography variant="h6" component="span" className={classes.textDD}>
            {formResponse.company.document}
          </Typography>
          <br />
          <Typography variant="h6" component="span" className={classes.textDL}>
            Data do Diagnóstico:
          </Typography>
          <Typography variant="h6" component="span" className={classes.textDD}>
            {format("dd/MM/y", parseISO(formResponse.created))}
          </Typography>
        </Grid>
      </Grid>
    </React.Fragment>
  );
};

export default CompanyData;

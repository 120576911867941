/* eslint-disable @typescript-eslint/no-unused-vars */
import React from "react";
import { withRouter } from "react-router-dom";
import history from "../../history";
import { TypographyStyled, CardMediaStyled } from "./styles";
import { CpfMask, PhoneMask } from "../../components/Field/InputMask";
import {
  Button,
  Checkbox,
  Container,
  CssBaseline,
  FormControlLabel,
} from "@mui/material";
import TextField from "@mui/material/TextField";
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import ptBRLocale from "date-fns/locale/pt-BR";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { useAuth } from "../../contexts/auth";
import LgpdDialog from "./lgpd_dialog";
import { makeStyles } from "@mui/styles";
import { LoginResponseData } from "../../types/auth";
import HelperCnpjCpf from "../../helpers/CnpjCpf";
import Swal from "sweetalert2";
import "@sweetalert2/theme-material-ui";

const { REACT_APP_API_BASE_URL } = process.env;
console.log(REACT_APP_API_BASE_URL);

const useStyles = makeStyles({
  textInput: {
    color: "white",
  },
});
const Index: React.FC = () => {
  const [document, setDocument] = React.useState("");
  const [email, setEmail] = React.useState("");
  const [phone, setPhone] = React.useState("");
  const [error, setError] = React.useState("");
  const [checked, setChecked] = React.useState(false);
  const [birth_date, setBirthDate] = React.useState<Date | null>(null);
  const [showRegister, setShowRegister] = React.useState(false);
  const classes = useStyles();
  const { Login, SignUp, user } = useAuth();

  React.useEffect(() => {
    if (user !== null && user.is_consultant === true) {
      history.push("/consultant_login");
    }
  }, [user]);

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setChecked(event.target.checked);
  };

  const submit = async (ev: React.SyntheticEvent) => {
    ev.preventDefault();
    if (showRegister === false) {
      getUserLogin();
    } else {
      setUserSignUp();
    }
  };

  const setUserSignUp = async () => {
    if (checked === true) {
      if (!document || !birth_date || !email || !phone) {
        setError("Preencha todos os campos para continuar!");
      } else {
        const signUp = await SignUp(
          document,
          birth_date.toISOString().split("T")[0],
          email,
          phone
        );
        if (signUp.error === true) {
          setError(signUp.message);
          setShowRegister(true);
        } else {
          history.push("/companies");
        }
      }
    } else {
      setError("Você precisa concordar com os termos para realizar o cadastro");
    }
  };

  const getUserLogin = async () => {
    if (checked === true) {
      if (!document || !birth_date) {
        setError("Preencha cpf e a data de nascimento para continuar!");
      } else {
        if (HelperCnpjCpf.validarCPF(document)) {
          const login: LoginResponseData = await Login(
            document,
            birth_date.toISOString().split("T")[0]
          );

          if (login.error === true) {
            if (login.message !== undefined && login.message !== null) {
              setError(login.message);
            } else {
              setError("Erro!");
            }
            setShowRegister(true);
          } else {
            if (login.user?.is_consultant === true) {
              history.push("/consultant_login");
            } else {
              history.push("/companies");
            }
          }
        } else {
          Swal.fire(
            "Documento inválido",
            "Verifique os números digitados",
            "warning"
          );
        }
      }
    } else {
      setError("Você precisa concordar com os termos para realizar o login");
    }
  };

  // const handleDateChange = (date: Date | null) => {
  //   setBirthDate(date);
  // };

  return (
    <Container component="main" maxWidth="xs">
      <CssBaseline />
      <div style={{ marginTop: "30%" }}>
        {error && (
          <TypographyStyled variant="body1" color="error" paragraph={true}>
            {error}
          </TypographyStyled>
        )}
        <form noValidate onSubmit={submit}>
          <TextField
            color="primary"
            margin="normal"
            required
            fullWidth
            id="outlined-username-input"
            label="CPF"
            onChange={(e) => setDocument(e.target.value)}
            name="document"
            autoFocus
            className={classes.textInput}
            InputProps={{
              inputComponent: CpfMask as any,
            }}
          />
          <LocalizationProvider
            dateAdapter={AdapterDateFns}
            adapterLocale={ptBRLocale}
          >
            <DatePicker
              label="Data de Nascimento"
              value={birth_date}
              onChange={(newValue) => {
                setBirthDate(newValue);
              }}
              renderInput={(params) => (
                <TextField fullWidth={true} {...params} />
              )}
            />
          </LocalizationProvider>
          {showRegister ? (
            <>
              <TextField
                color="primary"
                margin="normal"
                required
                fullWidth
                id="outlined-email-input"
                label="email"
                onChange={(e) => setEmail(e.target.value)}
                name="email"
                autoFocus
              />
              <TextField
                color="primary"
                margin="normal"
                required
                fullWidth
                id="outlined-phone-input"
                label="phone"
                onChange={(e) => setPhone(e.target.value)}
                name="phone"
                autoFocus
                InputProps={{
                  inputComponent: PhoneMask as any,
                }}
              />
            </>
          ) : (
            <></>
          )}
          <FormControlLabel
            control={
              <Checkbox
                color="primary"
                checked={checked}
                onChange={handleChange}
              />
            }
            style={{ marginTop: "30px", marginBottom: "30px" }}
            label="Eu aceito receber, por e-mail, este conteúdo, as comunicações e as informações do Sebrae Alagoas."
          />
          Ao informar meus dados, eu concordo com a &nbsp;
          <LgpdDialog />
          <Button
            type="submit"
            fullWidth
            variant="contained"
            color="primary"
            style={{ marginTop: "30px" }}
          >
            Entrar
          </Button>
        </form>
      </div>
    </Container>
  );
};

export default withRouter(Index);

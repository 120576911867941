import React from "react";
import {
  Checkbox,
  FormControlLabel,
} from "@mui/material";

interface Props {
  label: string;
  value: string;
  id: string;
  name: string;
  checked: boolean;
  color?: any;
  default?: [];
  onChange?: (ev) => void;
}

export const CheckboxField = (props: Props) => {

  return (
    <FormControlLabel
      value={props.value ? props.value : ""}
      checked={props.checked}
      control={<Checkbox color={props.color!=null?props.color:'primary'} />}
      label={props.label}
      onChange={props.onChange}
      name={props.name}
      id={props.name}
    />
  );
};

import history from "../../../history";
import {
  createFormResponse,
  listFormResponses,
  listFormResponsesCompany,
} from "../../../services/Campaign";
import { UserExternal } from "../../../types/auth";
import { Campaign, ResponseForm } from "../../../types/campaign";
import Swal from "sweetalert2";
import "@sweetalert2/theme-material-ui";

type campaignsSplited = {
  available: Campaign[];
  used: Campaign[];
};

const splitCampaign = (campaigns, formResponses) => {
  let campaignsSplited: campaignsSplited = { available: [], used: [] };
  const formResponsesIds = formResponses.map((response) => {
    if(response.campaign.uuid!==undefined) {
      return response.campaign.uuid;
    }else{
      return false;
    }
  });
  campaigns.forEach((campaign) => {
    let existe = formResponsesIds.find((id) => {
      return campaign.uuid === id;
    });
    if (existe !== undefined) {
      campaignsSplited.used.push(campaign);
    } else {
      campaignsSplited.available.push(campaign);
    }
  });
  return campaignsSplited;
};

async function loadFormResponsesCompany(companyUuid, user: UserExternal | undefined) {
  let responses: ResponseForm[] = [];
  if (user !== undefined && user?.uuid !== undefined) {
    if (companyUuid !== "") {
      const lsFormResponses = await listFormResponsesCompany(
        user?.uuid,
        companyUuid,
        true
      );
      if (lsFormResponses !== undefined) {
        responses = lsFormResponses;
      }
    } else {
      if (user !== undefined && user?.uuid !== undefined) {
        const lsFormResponses = await listFormResponses(
          user?.uuid
        );
        if (lsFormResponses !== undefined) {
          responses = lsFormResponses;
        }
      }
      // setFormResponses([]);
    }
  }
  return responses;
}
const openForm = async (
  ev,
  campaign: Campaign,
  user: UserExternal,
  companyUuid?: string,
  consultantUuid?: string
) => {
  if (user !== null) {
    const formResponseRet = await createFormResponse(
      campaign.form.uuid,
      campaign.uuid,
      user.uuid,
      companyUuid,
      false,
      1,
      consultantUuid
    );
    history.push(`/survey/${campaign.uuid}`, {
      Campaign: campaign,
      formData: {},
      ResponseForm: formResponseRet,
    });
    // history.push(`/editForm/${formResponseRet.uuid}`, { Campaign: campaign });
  }
};

const showAlertNotExists = (isCompany)=>{
    if (isCompany === false) {
        Swal.fire({
          title:
            "No momento não existe nenhum diagnóstico disponível para esse perfil",
          icon: "warning",
          confirmButtonText: "OK",
          timer: 5000,
          timerProgressBar: true,
        }).then(() => {
          history.push(`/companies/`);
        });
      } else {
        Swal.fire({
          title:
            "No momento não existe nenhum diagnóstico disponível para esse perfil. Adicione uma empresa para fazer as avaliações destinadas ao perfil.",
          icon: "warning",
          confirmButtonText: "OK",
          timer: 5000,
          timerProgressBar: true,
        }).then(() => {
          history.push(`/companies/`);
        });
      }
}

export { splitCampaign, loadFormResponsesCompany, openForm, showAlertNotExists };
export type { campaignsSplited };

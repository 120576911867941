import React from "react";
import emptyImage from "../../assets/img/campaignNoImage.png";
import AgendeAtendimentoImage from "../../assets/img/AgendeAtendimento.png";
import RodapeImage from "../../assets/img/rodapeSebrae.png";
import LogoSebrae from "../../assets/img/iconSebrae.png";
import { Grid, Paper, Typography, Link, Box } from "@mui/material";
import {
  AverangeDiagnosticResponse,
  DiagnosticResponse,
  ResponseDiagnosticSolutions,
} from "../../types/Diagnostic";
import { ResponseForm } from "../../types/campaign";

import QRCode from "qrcode.react";
import OfferedSolutions from "./offered_solutions";
import { useStyles } from "./styles";
import ComparativeCharts from "./comparative_charts";
import Speedometers from "./speedometers";
import TipsBox from "./tips";
import CompanyData from "./company_data";
// import CartData from "./cart_data";

const { REACT_APP_API_BASE_URL } = process.env;

interface IDiacnosticDocumentProps {
  diagnostic: DiagnosticResponse | undefined;
  averangeDiagnostic: AverangeDiagnosticResponse | undefined;
  themes: string[];
  formResponse: ResponseForm;
  solutions: ResponseDiagnosticSolutions | undefined;
}

const DiacnosticDocument: React.FC<IDiacnosticDocumentProps> = (props) => {
  const diagnostic = props.diagnostic;
  const averangeDiagnostic = props.averangeDiagnostic;
  const themes = props.themes;
  const formResponse = props.formResponse;
  const solutions = props.solutions;
  const baseUrl = window.location.protocol + "://" + window.location.host + "/";

  const classes = useStyles();
  return (
    <Paper elevation={0} style={{ padding: 10 }}>
      <Grid container spacing={0}>
        <Grid item md={12} lg={12} xs={12}>
          <Box
            style={{
              display: "flex",
              justifyContent: "space-between",
              padding: 0,
            }}
          >
            <Box>
              <img
                src={LogoSebrae}
                style={{
                  maxHeight: "100px",
                  textAlign: "right",
                  marginLeft: "10px",
                }}
                alt={"Logo Sebrae"}
              />
            </Box>

            <Box className={classes.boxTitle}>Resultado -Autodiagnóstico</Box>
            <Box>
              {formResponse?.campaign.image !== undefined &&
              formResponse?.campaign.image !== "" &&
              formResponse?.campaign.image !== null ? (
                <img
                  src={REACT_APP_API_BASE_URL + formResponse?.campaign.image}
                  // srcSet={REACT_APP_API_BASE_URL + formResponse?.campaign.image}
                  alt={"Header"}
                  style={{
                    maxHeight: "100px",
                    textAlign: "right",
                  }}
                />
              ) : (
                <img
                  src={emptyImage}
                  style={{
                    maxHeight: "100px",
                    textAlign: "right",
                  }}
                  alt={"Header"}
                />
              )}
            </Box>
          </Box>
          <CompanyData formResponse={formResponse} />
          {/* {diagnostic?.carteira !== undefined && (
            <React.Fragment>
              <CartData Carteira={diagnostic?.carteira} />
            </React.Fragment>
          )} */}

          <Box className={classes.line} />
          <Typography
            variant="h5"
            component="div"
            className={classes.centerTitle}
          >
            RESULTADO
          </Typography>
          <Typography
            variant="body2"
            component="p"
            style={{ textAlign: "justify", padding: 20 }}
          >
            A gestão de uma empresa requer uma visão sistêmica que promova a
            integração de diferentes áreas, e compreender como isso funcionada
            no dia a dia das operações é fundamental para alcançar melhores
            resultados. O 360+ é uma ferramenta de gestão desenvolvida pelo
            Sebrae que ajuda você a identificar os pontos fortes e as
            fragilidades que colocam em risco a lucratividade e a
            sustentabilidade do seu negócio.
          </Typography>
          <Typography
            variant="body2"
            component="p"
            style={{ textAlign: "justify", padding: 20 }}
          >
            Nos gráficos abaixo, trazemos uma visão geral da sua empresa nos
            temas Finanças, Mercado e Planejamento, Processos e Pessoas, e você
            também pode comparar seu presente momento com a média do mercado em
            que atua. Confira o resultado e agende uma consultoria com o Sebrae
            para definir as melhores estratégias para sua empresa.
          </Typography>
        </Grid>
        <Box className={classes.boxSubTitle}>Resultado da sua Empresa</Box>
        <Box className={classes.line} />

        <Grid container style={{ marginTop: "20px" }}>
          <Speedometers themes={themes} diagnostic={diagnostic} />
        </Grid>

        {averangeDiagnostic !== undefined &&
          themes !== undefined &&
          formResponse.form.market_comparison === true && (
            <ComparativeCharts
              themes={themes}
              averangeDiagnostic={averangeDiagnostic}
              diagnostic={diagnostic}
            />
          )}
        {solutions !== undefined && solutions !== null && Object.keys(solutions).length > 0 && (
          <OfferedSolutions
            themes={themes}
            formResponse={formResponse}
            solutions={solutions}
          ></OfferedSolutions>
        )}
        <Grid
          item
          md={12}
          lg={12}
          xs={12}
          style={{ textAlign: "center", padding: 20 }}
        >
          <Link href="https://sma.sebrae.al/" target="_blank" rel="noopener">
            <img
              src={AgendeAtendimentoImage}
              // srcSet={AgendeAtendimentoImage}
              alt={"Percentuais"}
              loading="lazy"
              style={{ width: "50%" }}
            />
          </Link>

          <TipsBox />

          <Grid item sm={12} md={12} lg={12} style={{ textAlign: "center" }}>
            <Typography
              variant="body1"
              component="p"
              className={classes.TextInfoQr}
            >
              Aponte a câmera do seu celular no QR Code para <br />
              acessar a versão digital do diagnóstico
            </Typography>
            <br />
            <QRCode
              value={baseUrl + `diagnostic/${formResponse.uuid}`}
              size={100}
              fgColor="#0145a7"
            />
          </Grid>

          <Box
            style={{
              paddingLeft: "10%",
              marginTop: "20px",
              paddingRight: "10%",
            }}
          >
            <img
              src={RodapeImage}
              // srcSet={AgendeAtendimentoImage}
              alt={"Percentuais"}
              loading="lazy"
              style={{ width: "50%" }}
            />
          </Box>
        </Grid>
      </Grid>
    </Paper>
  );
};

export default DiacnosticDocument;

import React from "react";
import { MenuItem, TextField } from "@mui/material";

export interface Option {
  label: string;
  value: string;
}
interface Props {
  name: string;
  label: string;
  default?: any;
  helperText?: string;
  onChange?: (ev) => void;
  options: Option[];
}

export const Select = (props: Props) => {
  return (
    <TextField
      id={props.name}
      name={props.name}
      select
      label={props.label}
      value={props.default?props.default:''}
      fullWidth={true}
      onChange={props.onChange} 
      helperText={props.helperText}
      variant="outlined"
      // defaultValue={props.default?props.default: ''}
      hiddenLabel
    >
      {props.options.map((option) => (
        <MenuItem key={option.value} value={option.value}>
          {option.label}
        </MenuItem>
      ))}
    </TextField>
  );
};

import * as React from "react";
import {
  Box,
  Card,
  CardContent,
  Typography,
  Grid,
} from "@mui/material";
import { makeStyles } from "@mui/styles";

const useStyles = makeStyles((theme: any) => ({
  heroContent: {
    padding: theme.spacing(8, 0, 6),
  },
  iconList: {
    fontSize: "10px",
    color: "#bfcd00",
    minWidth: "20px",
  },
  cardCampaign: {
    minWidth: "80%",
    maxWidth: "100%",
    backgroundColor: "#073e7b",
    border: "5px solid #0044a6",
    outline: "2px solid #FFFFFF",
    borderRadius: "15px",
    marginTop: "10px"
  },
  boxCardCampaign: {
    display: "flex",
    justifyContent: "center",
    p: 1,
    m: 1,
    bgcolor: "background.paper",
    borderRadius: 1,
  },
  buttonStyle: {
    color: "#FFFFFF",
    boxShadow: "1px 5px #00c4ff",
  },
}));

type ICardStyledProps = {
  title: string;
  children?: JSX.Element;
};

const CardStyled: React.FunctionComponent<ICardStyledProps> = (props) => {
  const classes = useStyles();
  
  return (
    <Grid item md={12} lg={12} xs={12}>
      <Box className={classes.boxCardCampaign}>
        <Card className={classes.cardCampaign}>
          <CardContent>
            <Typography
              gutterBottom
              variant="h5"
              component="div"
              style={{ color: "#bfcd00" }}
            >
              {props.title}
            </Typography>

            {props.children}
          </CardContent>
        </Card>
      </Box>
    </Grid>
  );
};

export default CardStyled;

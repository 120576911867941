import * as React from "react";
import {
  Box,
  Button,
  ButtonGroup,
  Card,
  CardContent,
  Grid,
  Typography,
} from "@mui/material";
import { ResponseForm } from "../../types/campaign";
import { LinearProgressWithLabel } from "../ProgressBar";
import { Link as RouterLink } from "react-router-dom";
import { format } from "date-fns/fp";
import { parseISO, isAfter, addDays } from "date-fns";
import { User } from "../../types/auth";
import { sendDiagnosticEmail } from "../../services/DiagnosticService";
import Swal from "sweetalert2";
import "@sweetalert2/theme-material-ui";

interface IResponsesProps {
  formResponses: ResponseForm[];
  user: User | null;
  is_consultant?: boolean;
}

const Responses: React.FunctionComponent<IResponsesProps> = (props) => {
  const [formResponses, setFormResponses] = React.useState<
    ResponseForm[] | null
  >(null);
  React.useEffect(() => {
    setFormResponses(props.formResponses);
  }, [props]);
  const today = new Date();
  const sendEmail = (user: User | null, formResponse: ResponseForm) => {
    if (user !== null && user.email !== "") {
      Swal.fire({
        title: "Confirmar o envio!",
        text: `Deseja enviar o diagnostico para o seu e-mail cadastrado? '${user.email}'`,
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Sim, enviar!",
      }).then((result) => {
        if (result.isConfirmed) {
          sendDiagnosticEmail(formResponse.uuid, user.email);
          Swal.fire(
            "E-mail Enviado!",
            "Confira na sua caixa de mensagens.",
            "success"
          );
        }
      });
    }
  };

  return (
    <React.Fragment>
      {formResponses != null && formResponses.length > 0 ? (
        <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
          {formResponses?.map((formResponse, ind) => {
            let dateReviewEdit = parseISO(formResponse.created);
            dateReviewEdit = addDays(
              dateReviewEdit,
              formResponse.form.review_editing_time
            );
            return (
              <Grid key={ind} item md={6} lg={6} xs={12}>
                <Card sx={{ minWidth: 275 }}>
                  <CardContent>
                    <Box style={{ display: "flex" }}>
                      <Box style={{ width: "70%" }}>
                        <Typography gutterBottom variant="h5" component="div">
                          {formResponse.campaign.name}
                        </Typography>
                        <Typography variant="body2" color="text.secondary">
                          Iniciado :{" "}
                          {format("dd/MM/y", parseISO(formResponse.created))}
                          &nbsp;
                          {formResponse.is_completed === true && (
                            <React.Fragment>
                              | Concluído :{" "}
                              {format(
                                "dd/MM/y",
                                parseISO(formResponse.modified)
                              )}
                            </React.Fragment>
                          )}
                        </Typography>
                        <Typography variant="body1" color="text.secondary">
                          Respondido por:{" "}
                          {formResponse.external_users.user.first_name}{" "}
                          {formResponse.external_users.user.last_name}
                        </Typography>
                        <Typography variant="body2" color="text.secondary">
                          Data Limite para revisão:{" "}
                          {format("dd/MM/y", dateReviewEdit)}
                        </Typography>
                        <Typography variant="body2" color="text.secondary">
                          Validade:{" "}
                          {format("dd/MM/y", parseISO(formResponse.validaty))}
                        </Typography>
                      </Box>
                      <Box style={{ width: "30%" }}>
                        <ButtonGroup
                          orientation="vertical"
                          aria-label="vertical outlined button group"
                        >
                          {isAfter(dateReviewEdit, today) &&
                            ((props.is_consultant === true &&
                              formResponse.campaign
                                .is_selectable_by_consultant === true) ||
                              props.is_consultant !== true) && (
                              <Button
                                color="primary"
                                variant="contained"
                                style={{ color: "#FFFFFF", float: "right" }}
                                // onClick={() => editAvaliation(formResponse.uuid)}
                                component={RouterLink}
                                to={`/editForm/${formResponse.uuid}`}
                              >
                                Editar
                              </Button>
                            )}
                          {formResponse.is_completed === true && (
                            <Button
                              color="warning"
                              variant="contained"
                              style={{ color: "#FFFFFF", float: "right" }}
                              // onClick={() => editAvaliation(formResponse.uuid)}
                              component={RouterLink}
                              to={`/surveyResponses/${formResponse.uuid}`}
                            >
                              Visualizar
                            </Button>
                          )}
                        </ButtonGroup>
                      </Box>
                    </Box>
                    <Box style={{ display: "flex", marginTop: "10px" }}>
                      <Box style={{ width: "40%" }}>
                        <Typography
                          variant="body2"
                          color="text.secondary"
                          alignContent={"center"}
                        >
                          {formResponse.is_completed === true
                            ? "Completo!"
                            : "Pendente"}
                        </Typography>
                        <LinearProgressWithLabel
                          value={
                            formResponse.progress !== null
                              ? formResponse.progress
                              : 0
                          }
                        />
                      </Box>
                      <Box style={{ width: "60%" }}>
                        {formResponse.is_completed === true &&
                          formResponse.form.confirmation_message_mode !==
                            "sem_devolutiva" && (
                            <ButtonGroup
                            variant="contained" aria-label="outlined primary button group"
                            >
                              <Button
                                color="info"
                                variant="contained"
                                style={{ color: "#FFFFFF"}}
                                // onClick={() => editAvaliation(formResponse.uuid)}
                                component={RouterLink}
                                to={`/diagnostic/${formResponse.uuid}`}
                              >
                                Diagnostico
                              </Button>
                              <Button
                                color="primary"
                                variant="contained"
                                style={{ color: "#FFFFFF"}}
                                onClick={() =>
                                  sendEmail(props.user, formResponse)
                                }
                              >
                                Env. p/ E-mail
                              </Button>
                            </ButtonGroup>
                          )}
                      </Box>
                    </Box>
                  </CardContent>
                </Card>
              </Grid>
            );
          })}
        </Grid>
      ) : (
        <Box>Nenhuma Avaliação encontrada</Box>
      )}
    </React.Fragment>
  );
};

export default Responses;

/* eslint-disable @typescript-eslint/no-unused-vars */
import { Card, CardContent, Typography } from "@mui/material";
import React, { useEffect } from "react";
import { QuestionForm } from "../../types/campaign";
import { QuestionComponent } from "./question";

type Props = {
  questions: QuestionForm[];
  pushDataFnc?: (ind, val) => void;
  formik;
};

export const Questions = (props: Props) => {
  const [questionsForm, setQuestionForms] = React.useState<QuestionForm[]>();
  useEffect(() => {
    if (props.questions) {
      setQuestionForms(props.questions);
    }
  }, [props]);
  return (
    <React.Fragment>
      {questionsForm?.map((questionsForm, i) => (
        <Card key={i} sx={{}}>
          <CardContent>
            <Typography gutterBottom variant="h5" component={"div"}>
              {questionsForm.question?.name}
            </Typography>
            <Typography component={"span"} variant={"body2"}>
              {questionsForm.question?.description}
            </Typography>
            <QuestionComponent formik={props.formik} questionForm={questionsForm} />
          </CardContent>
        </Card>
      ))}
    </React.Fragment>
  );
};

import * as React from "react";
import { useAuth } from "../../contexts/auth";
import { getClientsCard } from "../../services/DiagnosticService";
import { UserCompanies, UserExternal } from "../../types/auth";
import { CartCompanies } from "../../types/Diagnostic";
import CompanyCard from "./components/company_card";
import PeopleCard from "./components/people_card";

interface IClientsCartProps {
  selectClient: (client: UserExternal, company?: UserCompanies) => void;
}

const ClientsCart: React.FunctionComponent<IClientsCartProps> = (props) => {
  const { user } = useAuth();
  const [consulted, setConsulted] = React.useState(false);
  const [companies, setCompanies] = React.useState<CartCompanies[]>();
  const [peoples, setPeoples] = React.useState<UserExternal[]>();
  React.useEffect(() => {
    const loadClients = async () => {
      if (user !== null) {
        const resp = await getClientsCard(user?.external_user.uuid);

        setCompanies(resp.companies);
        setPeoples(resp.people);
      }
    };
    if (consulted === false) {
      loadClients();
      setConsulted(true);
    }
  }, [consulted, user]);
  return (
    <React.Fragment>
      <h5>Carteira de Clientes</h5>
      {companies !== undefined && (
        <React.Fragment>
          <h6>Empresas</h6>
          {companies.map((company) => (
            <CompanyCard
              company={company.company}
              client={company.externalUser}
              selectClient={props.selectClient}
            />
          ))}
        </React.Fragment>
      )}
      {peoples !== undefined && (
        <React.Fragment>
          <h6>Pessoas Fisicas</h6>
          {peoples.map((people) => {
            return <PeopleCard people={people}  selectClient={props.selectClient} />;
          })}
        </React.Fragment>
      )}
    </React.Fragment>
  );
};

export default ClientsCart;

import styled from "styled-components";
import { TextField } from "@mui/material";

export default styled(TextField)`
    ${({ error }) =>
        error &&
        `
        input {
            border: #f00 1px solid;
            border-radius: 4px;
        }
    `}
`;

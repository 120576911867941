/* eslint-disable @typescript-eslint/no-unused-vars */
import api from "./api";
import { AxiosResponse } from "axios";
import { UserExternal } from "../types/auth";

const findUserByCnpj = async (cnpj) => {
  const filter =
    "?filter=companies__document&operator=contains&value=" +
    cnpj.replace(".", "").replace(".", "").replace("/", "").replace("-", "");
  const response: AxiosResponse = await api.get(
    `api/consultant/users/${filter}`
  );
  const companies: UserExternal[] = response.data;
  return companies;
};

const findUserByCpf = async (cpf) => {
  const filter =
    "?filter=user__username&operator=contains&value=" +
    cpf.replace(".", "").replace(".", "").replace("-", "");
  const response: AxiosResponse = await api.get(
    `api/consultant/users/${filter}`
  );
  const companies: UserExternal[] = response.data;
  return companies;
};
const getUser = async (uuid) => {
  const response: AxiosResponse = await api.get(
    `api/consultant/users/${uuid}/`
  );
  const companies: UserExternal = response.data;
  return companies;
};

export { findUserByCnpj, findUserByCpf, getUser };

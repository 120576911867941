/* eslint-disable no-undef */
import axios from "axios";
// import { useAuth } from "../contexts/auth";
// import { useLoading } from "../contexts/loading";
// import { AuthData } from "../types/auth";
import history from "../history";

// eslint-disable-next-line no-undef
const { REACT_APP_API_BASE_URL } = process.env;

const api = axios.create({
  baseURL: REACT_APP_API_BASE_URL,
});

// Add a request interceptor
api.interceptors.request.use(
  (config) => {
    // const { setShowLoading } = useLoading();
    // setShowLoading(false);
    document.body.classList.add("loading-indicator");
    let access_token = sessionStorage.getItem("access_token");
    if (access_token !== null) {
      config.headers = {
        Authorization: `JWT ${access_token}`,
        "Content-Type": "application/json",
        Accept: "application/json",
      };
    } else {
      config.headers = {
        "Content-Type": "application/json",
        Accept: "application/json",
      };
    }

    return config;
  },
  (error) => Promise.reject(error)
);

// Add a response interceptor
try {
  api.interceptors.response.use(
    (response) => {
      document.body.classList.remove("loading-indicator");
      return response;
    },
    async (error) => {
      const {
        config,
        response: { status, data },
      } = error;

      const originalRequest = config;

      // console.log(originalRequest.url);
      if (status === 401) {
        if (
          originalRequest.url === `api/login` ||
          originalRequest.url === `api/login_consultant`
        ) {
          document.body.classList.remove("loading-indicator");
          data['error'] = true;
          return Promise.resolve(data);
        } else {
          sessionStorage.clear();
          history.push("/login");
          document.body.classList.remove("loading-indicator");
        }
      } else {
        if (status === 403) {
          sessionStorage.removeItem("access_token");
          originalRequest.headers.Authorization = undefined;

          return (window.location.href = "/");
        }
      }

      return Promise.reject(error);
    }
  );
} catch (error) {
  console.log(error);
}

export default api;

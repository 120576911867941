/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useEffect } from "react";
// import Survey from "./index";
import { getCampaign, createFormResponse } from "../../services/Campaign";
import { Campaign, ResponseForm } from "../../types/campaign";
import history from "../../history";
import { RouteComponentProps, StaticContext } from "react-router";
import { CssBaseline } from "@mui/material";
import { useSurvey } from "../../contexts/survey";
import { useAuth } from "../../contexts/auth";

interface MatchParams {
  campaignUuid: string;
}
export const OpenPublic = (
  props: RouteComponentProps<MatchParams, StaticContext>
) => {
  const [formData, setFormData] = React.useState<object>();
  const [responseForm, setResponseForm] = React.useState<ResponseForm>();
  const [campaign, setCampaign] = React.useState<Campaign>();
  const { SaveCampaign }  = useSurvey()
  const {signed} = useAuth();

  useEffect(() => {
    const loadCampain = async (uuid) => {
      if (uuid !== undefined) {
        const responseCampaing: Campaign = await getCampaign(uuid);
        setCampaign(responseCampaing);
      }
    };

    const loadFormResponses = async (campaignUuid, formUuid) => {
      const formResponseRet = await createFormResponse(
        formUuid,
        campaignUuid,
        null,
        undefined
      );
      setFormData({});
      // formik.setValues(responses);
      setResponseForm(formResponseRet);
      console.log(formResponseRet)
    };

    if(campaign === undefined){
      loadCampain(props.match.params.campaignUuid);
    }

    // console.log(props);
    if (responseForm === undefined && campaign !== undefined) {
      if(campaign.form.only_company===true){
        SaveCampaign(campaign)
        if(!signed){
          history.push(`/login/`);
        }else{
          history.push(`/companies/`);
        }
      }else{
        loadFormResponses(campaign?.uuid, campaign?.form.uuid);
      }
    }
    // loadFormResponses(props.match.params.campaignUuid);

    if (campaign !== undefined && formData !== undefined && responseForm !==undefined) {
      
      history.push(`/survey/${campaign.uuid}`, {
        Campaign: campaign,
        formData: formData,
        ResponseForm: responseForm,
      });
    }
  }, [props, campaign, responseForm, formData, signed, SaveCampaign]);

  return (
    <React.Fragment>
      <CssBaseline />
      Carregando..
    </React.Fragment>
  );
};

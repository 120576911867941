/* eslint-disable @typescript-eslint/no-unused-vars */
import api from "./api";
import { AxiosResponse } from "axios";
import { ResponseForm } from "../types/campaign";

const getUnfinishedReponses = async () => {
  const response: AxiosResponse = await api.get("api/external/consultant/responseForms/");
  const responses: ResponseForm[] = response.data;
  return responses;
};

export { getUnfinishedReponses };

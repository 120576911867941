import { DiagnosticResponse } from "../../types/Diagnostic";

const getPercent = (
    theme: string,
    diagnostic: DiagnosticResponse | undefined
) => {
    let percent = 0;
    if (diagnostic !== undefined) {
        if (diagnostic.sumPoints[theme] > 0) {
            percent =
                (diagnostic?.points[theme] * 100) / diagnostic.sumPoints[theme];
        }
    }
    return percent;
};
const getPercentMarket = (theme: string, averangeDiagnostic) => {
    let percent = 0;
    if (
        averangeDiagnostic !== null &&
        averangeDiagnostic !== undefined &&
        averangeDiagnostic.averange !== undefined &&
        averangeDiagnostic.averange[theme] !== undefined
    ) {
        return averangeDiagnostic.averange[theme];
    }
    return percent;
};
const getFormatedNumber = (value) => {
    if (value === undefined) {
        value = 0;
    }
    if (value === 0 || value === "0") {
        return "0,00";
    } else {
        return value.toFixed(2);
    }
};

export { getPercent, getPercentMarket, getFormatedNumber };

import * as React from "react";
import { Box, Button, Grid, Typography } from "@mui/material";
import { format } from "date-fns/fp";
import { addDays, isAfter, parseISO } from "date-fns";
import CardStyled from "../../components/CardStyled";
import { Link as RouterLink } from "react-router-dom";
import { LinearProgressWithLabel } from "../../components/ProgressBar";
import { getUnfinishedReponses } from "../../services/ConsultantService";
import { ResponseForm } from "../../types/campaign";

interface IUnfinishedResponsesProps {}

const UnfinishedResponses: React.FunctionComponent<
  IUnfinishedResponsesProps
> = (props) => {
  const [responses, setResponses] = React.useState<ResponseForm[]>();
  const today = new Date();

  React.useEffect(() => {
    const loadUnfinishedResponses = async () => {
      const resps = await getUnfinishedReponses();
      setResponses(resps);
    };
    if (responses === undefined) {
      loadUnfinishedResponses();
    }
  }, [responses]);

  const getTitle = (response: ResponseForm) => {
    if (response.company !== null) {
      return (
        response.company.name +
        " - " +
        response.company.document.replace(
          /^(\d{2})(\d{3})(\d{3})(\d{4})(\d{2}).*/,
          "$1.$2.$3/$4-$5"
        )
      );
    } else {
      return (
        response.external_users.user.first_name +
        " " +
        response.external_users.user.last_name +
        " - " +
        response.external_users.user.document.replace(
          /^(\d{3})(\d{3})(\d{3})(\d{2}).*/,
          "$1.$2.$3-$4"
        )
      );
    }
  };

  const getReviewDateEdit = (response) => {
    return addDays(
      parseISO(response.created),
      response.form.review_editing_time
    );
  };

  return (
    <React.Fragment>
      {responses !== undefined && (
        <Grid container>
          {responses.map((response) => (
            <Grid item md={12} lg={12} xs={12}>
              <CardStyled title={getTitle(response)}>
                <>
                  <Box style={{ display: "flex" }}>
                    <Box style={{ width: "70%" }}>
                      <Typography gutterBottom variant="h5" component="div">
                        {response.campaign.name}
                      </Typography>
                      <Typography variant="body2" color="text.secondary">
                        Iniciado :
                        {format("dd/MM/y", parseISO(response.created))}
                        &nbsp;
                        {response.is_completed === true && (
                          <React.Fragment>
                            | Concluído :{" "}
                            {format("dd/MM/y", parseISO(response.modified))}
                          </React.Fragment>
                        )}
                      </Typography>
                      <Typography variant="body1" color="text.secondary">
                        Respondido por:{" "}
                        {response.external_users.user.first_name}{" "}
                        {response.external_users.user.last_name}
                      </Typography>
                      <Typography variant="body2" color="text.secondary">
                        Data Limite para revisão:
                        {format("dd/MM/y", getReviewDateEdit(response))}
                      </Typography>
                      <Typography variant="body2" color="text.secondary">
                        Validade:
                        {format("dd/MM/y", parseISO(response.validaty))}
                      </Typography>
                    </Box>
                    <Box style={{ width: "30%" }}>
                      <Typography component="h6" variant="h6">
                        Contato:
                      </Typography>
                      E-mail: <b>{response.external_users.user.email}</b><br />
                      Telefone: <b>{response.external_users.phone}</b>
                    </Box>
                  </Box>
                  <Box style={{ display: "flex", marginTop: "10px" }}>
                    <Box style={{ width: "40%" }}>
                      <Typography
                        variant="body2"
                        color="text.secondary"
                        alignContent={"center"}
                      >
                        {response.is_completed === true
                          ? "Completo!"
                          : "Pendente"}
                      </Typography>
                      <LinearProgressWithLabel
                        value={
                          response.progress !== null ? response.progress : 0
                        }
                      />
                    </Box>
                    <Box style={{ width: "60%" }}>
                      {response.campaign.is_selectable_by_consultant ? (
                        ""
                      ) : (
                        <Typography
                          component="span"
                          variant="body2"
                          sx={{ float: "right", color: "grey" }}
                        >
                          Campanha não selecionavel pelo consultor*
                        </Typography>
                      )}
                      {isAfter(getReviewDateEdit(response), today) &&
                        response.campaign.is_selectable_by_consultant ===
                          true && (
                          <Button
                            color="primary"
                            variant="contained"
                            style={{ color: "#FFFFFF", float: "right" }}
                            // onClick={() => editAvaliation(formResponse.uuid)}
                            component={RouterLink}
                            to={`/editForm/${response.uuid}`}
                          >
                            Editar
                          </Button>
                        )}
                    </Box>
                  </Box>
                </>
              </CardStyled>
            </Grid>
          ))}
        </Grid>
      )}
    </React.Fragment>
  );
};

export default UnfinishedResponses;

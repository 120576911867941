import { makeStyles } from "@mui/styles";


const useStyles = makeStyles((theme: any) => ({
  textDL: {
    color: "#028ac8",
    fontWeight: "bold",
  },
  textDD: {
    color: "#665e5e",
    marginLeft: "10px",
  },
  boxTitle: {
    backgroundColor: "#028ac8",
    height: "60px",
    width: "40%",
    fontSize: "25px",
    fontWeight: "bold",
    textAlign: "center",
    color: "white",
    padding: "10px",
    borderRadius: "10px 10px 10px 10px",
  },
  boxSubTitle: {
    backgroundColor: "#028ac8",
    width: "30%",
    textAlign: "center",
    color: "white",
    padding: "5px 5px",
    borderRadius: "10px 10px 0px 0px",
    marginTop: "40px",
    fontSize: "20px",
    fontWeight: "bold",
  },
  centerTitle: {
    color: "#028ac8",
    textAlign: "center",
    marginTop: "10px",
    marginBottom: "10px",
  },
  line: {
    backgroundColor: "#028ac8",
    width: "100%",
    borderBottom: "1px solid #028ac8",
    height: "1px",
    maxHeight: "1px",
  },
  TextInfoQr: {
    color: "#028ac8",
    fontWeight: "bold",
    textAlign: "center",
  },
  titleTable: {
    margin: "0px",
    padding: "5px 5px",
    color: "white",
    backgroundColor: "#028ac8",
    width: "45%",
    borderRadius: "3px 10px 0px 0px",
    float: "left",
    textAlign: "left",
    fontSize: "20px",
    fontWeight: "bold",
  },
}));

export {useStyles};
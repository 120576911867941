/* eslint-disable @typescript-eslint/no-unused-vars */
import api from "./api";
import { AxiosResponse } from "axios";
import {
  AverangeDiagnosticResponse,
  ClientCartsResponse,
  DiagnosticResponse,
} from "../types/Diagnostic";

const getDiagnostic = async (formResponseUuid) => {
  // api/external/diagnostic/
  const response: AxiosResponse = await api.get(
    `api/external/diagnostic/${formResponseUuid}/`
  );
  const resp: DiagnosticResponse = response.data;
  return resp;
};
const getDiagnosticAverange = async (companyUuid) => {
  // busca media de diagnosticos por segmento da empresa
  const response: AxiosResponse = await api.get(
    `/api/external/diagnosticAverange/${companyUuid}/`
  );
  const resp: AverangeDiagnosticResponse = response.data;
  return resp;
};

const getRecommendedSolutions = async (formResponseUuid) => {
  const response: AxiosResponse = await api.get(
    `/api/external/diagnosticQuestionSolutions/${formResponseUuid}/`
  );
  const resp = response.data;
  return resp;
};

const sendDiagnosticEmail = async (formResponseUuid, email) => {
  const response: AxiosResponse = await api.post(
    `/api/external/diagnosticEmail/${formResponseUuid}/`,
    { to_mail: email }
  );
  const resp = response.data;
  return resp;
};

const getClientsCard = async (consultantUuid:string) =>{
  const response: AxiosResponse = await api.get(`/api/external/consultantClients/${consultantUuid}/`);
  const resp: ClientCartsResponse = response.data;
  return resp;
}

export { getDiagnostic, getDiagnosticAverange, getRecommendedSolutions, sendDiagnosticEmail, getClientsCard };

/* eslint-disable @typescript-eslint/no-unused-vars */
import api from "./api";
import { AxiosResponse } from "axios";
import {
  GetCompanyResponse,
  SetCompanyResponse
} from "../types/Company";
import { UserCompanies } from "../types/auth";

const findCompanySAS = async (document, email, phone, codPeopleSas = "") => {
  let data = `?document=${document}&email=${email}&phone=${phone}&cod_parceiro_pf=${codPeopleSas}`;
  const response: AxiosResponse = await api.get(
    `api/external/company/getCompanySas/${data}`
  );
  const result: GetCompanyResponse = response.data;
  if (result.error === false) {
    return result.data;
  } else {
    console.log(result.message);
    return false;
  }
};
const addCompanyUser = async (
  externalUserUuid,
  document,
  email,
  phone,
  codPeopleSas = ""
) => {
  try {
    let data = {
      document: document,
      email: email,
      phone: phone,
      cod_parceiro_pf: codPeopleSas,
    };
    const response: AxiosResponse = await api.post(
      `api/external/company/addCompanyUser/${externalUserUuid}/`,
      data
    );
    const result: SetCompanyResponse = response.data;
    return result;
  } catch (error) {
    console.log(error);
  }
};

const getUserCompanies = async (externalUserUuid) => {
  const response: AxiosResponse = await api.get(
    `api/external/company/companysByUser/${externalUserUuid}/`
  );
  const companies: UserCompanies[] = response.data;
  return companies;
};
const getCompany = async (companyUuid) => {
  const response: AxiosResponse = await api.get(
    `api/external/company/${companyUuid}/`
  );
  const companies: UserCompanies = response.data;
  return companies;
};

const deleteCompany = async (companyUuid, externalUserUuid) => {
  const response: AxiosResponse = await api.delete(
    `api/external/company/${companyUuid}/${externalUserUuid}/`
  );
  const dataResp = response.data;
  return dataResp;
};

const editCompany = async (companyUuid, data) => {
  const response: AxiosResponse = await api.patch(
    `api/external/company/${companyUuid}/`,
    data
  );
  const dataResp:SetCompanyResponse = response.data;
  return dataResp;
};
export { findCompanySAS, addCompanyUser, getUserCompanies, getCompany, deleteCompany, editCompany };

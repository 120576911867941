import { Button, Card, CardContent, Typography } from "@mui/material";
import * as React from "react";
import { UserCompanies, UserExternal } from "../../../types/auth";

interface ICompanyCardProps {
  client: UserExternal;
  company: UserCompanies;
  selectClient: (client: UserExternal, company?: UserCompanies) => void;
}

const CompanyCard: React.FunctionComponent<ICompanyCardProps> = (props) => {
  return (
    <Card sx={{ marginBottom: "10px" }}>
      <CardContent
        sx={{
          display: "flex",
          justifyContent: "space-between",
        }}
      >
        <Typography variant="body2" component="div">
          {props.company.name} -{" "}
          {props.company.document.replace(
            /^(\d{2})(\d{3})(\d{3})(\d{4})(\d{2}).*/,
            "$1.$2.$3/$4-$5"
          )}
        </Typography>
        <Button
          style={{ marginLeft: "5px", float: "right" }}
          variant="contained"
          color="primary"
          aria-label="add"
          onClick={(ev) => {
            // console.log(props.company)
            props.selectClient(props.client, props.company);
          }}
        >
          Acessar Detalhes
        </Button>
      </CardContent>
    </Card>
  );
};

export default CompanyCard;

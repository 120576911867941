/* eslint-disable @typescript-eslint/no-unused-vars */
import * as React from "react";
import { LinearProgress, Typography, LinearProgressProps } from "@mui/material";
import { Box } from "@mui/system";

interface IProgressProps {
  formik: any;
  questions: string[];
  responses: object | undefined;
  progressUpdateCallback(progress:number, totalQuestions: number, totalResponses: number): void;
}
function LinearProgressWithLabel(
  props: LinearProgressProps & { value: number }
) {
  return (
    <Box sx={{ display: "flex", alignItems: "center" }}>
      <Box sx={{ width: "100%", mr: 1 }}>
        <LinearProgress variant="determinate"  {...props} />
      </Box>
      <Box sx={{ minWidth: 35 }}>
        <Typography variant="body2" color="text.secondary">{`${Math.round(
          props.value
        )}%`}</Typography>
      </Box>
    </Box>
  );
}
const Progress: React.FunctionComponent<IProgressProps> = (props) => {
  const [progress, setProgress] = React.useState<number>(1);
  React.useEffect(() => {
    const totalQuestions: number = props.questions.length;
    let countResponses = 0;
    props.questions.forEach((quest) => {
      if (
        props.formik.values !== undefined &&
        typeof props.formik.values[quest] !== "undefined"
      ) {
        countResponses++;
      }
    });
    const perc: number = parseFloat(((countResponses * 100) / totalQuestions).toFixed(2));
    setProgress(perc);
    props.progressUpdateCallback(perc, totalQuestions, countResponses)
  }, [progress, props]);
  return (
    <React.Fragment>
      <LinearProgressWithLabel value={progress} color="success" sx={{height: "8px", borderRadius: "5px"}} />
    </React.Fragment>
  );
};

export default Progress;

import * as React from "react";
import {
  Box,
  Card,
  CardContent,
  Typography,
  Grid,
  List,
  ListItem,
  ListItemText,
  ListItemIcon,
} from "@mui/material";
import { Campaign } from "../../types/campaign";
import FiberManualRecordIcon from "@mui/icons-material/FiberManualRecord";
import { makeStyles } from "@mui/styles";

const useStyles = makeStyles((theme: any) => ({
  heroContent: {
    padding: theme.spacing(8, 0, 6),
  },
  iconList: {
    fontSize: "10px",
    color: "#bfcd00",
    minWidth: "20px",
  },
  cardCampaign: {
    minWidth: "80%",
    maxWidth: "100%",
    backgroundColor: "#073e7b",
    border: "5px solid #0044a6",
    outline: "2px solid #FFFFFF",
    borderRadius: "15px",
  },
  boxCardCampaign: {
    display: "flex",
    justifyContent: "center",
    p: 1,
    m: 1,
    bgcolor: "background.paper",
    borderRadius: 1,
  },
  buttonStyle: {
    color: "#FFFFFF",
    boxShadow: "1px 5px #00c4ff",
  },
}));

interface ICampainCardProps {
  campaign: Campaign;
  actionComponent: any;
}

const CampainCardAction: React.FunctionComponent<ICampainCardProps> = (props) => {
  const classes = useStyles();
  const campaign = props.campaign;
  return (
    <Grid item md={12} lg={12} xs={12}>
      <Box className={classes.boxCardCampaign}>
        <Card className={classes.cardCampaign}>
          <CardContent>
            <Typography
              gutterBottom
              variant="h5"
              component="div"
              style={{ color: "#bfcd00" }}
            >
              {campaign.name}
            </Typography>
            <Typography variant="body2" color="text.secondary">
              {campaign.spotlight}
            </Typography>

            <Grid container>
              <Grid item md={6}>
                {campaign.form.only_company === true && (
                  <Box sx={{ margin: "5px" }}>
                    <Typography
                      variant="caption"
                      color="text.dark"
                      component="div"
                      style={{
                        padding: "5px",
                        borderBottom: "2px solid #bfcd00",
                        float: "left",
                        paddingBottom: "2px",
                        marginBottom: "5px",
                      }}
                    >
                      Quem pode fazer esse diagnóstico:
                    </Typography>
                    <List dense>
                      <ListItem disablePadding>
                        <ListItemIcon className={classes.iconList}>
                          <FiberManualRecordIcon fontSize={"inherit"} />
                        </ListItemIcon>
                        <ListItemText primary="Microempresa" />
                      </ListItem>
                      <ListItem disablePadding>
                        <ListItemIcon className={classes.iconList}>
                          <FiberManualRecordIcon fontSize={"inherit"} />
                        </ListItemIcon>
                        <ListItemText primary="Empresas de Pequeno Porte" />
                      </ListItem>
                      <ListItem disablePadding>
                        <ListItemIcon className={classes.iconList}>
                          <FiberManualRecordIcon fontSize={"inherit"} />
                        </ListItemIcon>
                        <ListItemText primary="Micro Empreendedor Individual (MEI)" />
                      </ListItem>
                    </List>
                  </Box>
                )}
              </Grid>
              <Grid
                item
                md={6}
                sx={{
                  display: "flex",
                  justifyContent: "flex-end",
                  alignItems: "flex-end",
                }}
              >
                {props.actionComponent}
              </Grid>
            </Grid>
          </CardContent>
        </Card>
      </Box>
    </Grid>
  );
};

export default CampainCardAction;

import * as React from "react";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import {
  Grid,
  Paper,
  Table,
  TableBody,
  TableContainer,
  TableRow,
  Typography,
  styled,
  //   Link,
  Box,
  Link,
} from "@mui/material";
import { ResponseDiagnosticSolutions } from "../../types/Diagnostic";
import { ResponseForm } from "../../types/campaign";
import { useStyles } from "./styles";

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(odd)": {
    backgroundColor: theme.palette.action.hover,
  },
  // hide last border
  "&:last-child td, &:last-child th": {
    border: 0,
  },
}));

interface IOfferedSolutionsProps {
  themes: string[];
  formResponse: ResponseForm;
  solutions: ResponseDiagnosticSolutions | undefined;
}

const OfferedSolutions: React.FC<IOfferedSolutionsProps> = (props) => {
  const [themes] = React.useState(props.themes);
  const [solutions] = React.useState(props.solutions);
  const [formResponse] = React.useState(props.formResponse);
  const classes = useStyles();

  const getCount = (ind) => {
    return ind + 1;
  };
  return (
    <Grid item md={12} lg={12} xs={12} style={{ marginTop: 10, padding: 20 }}>
      <Box className={classes.line} />
      <Typography
        variant="h5"
        component="div"
        className={classes.centerTitle}
        style={{ marginBottom: "10px", marginTop: "30px" }}
      >
        SOLUÇÕES PARA SEU NEGÓCIO
      </Typography>
      Separamos para você um conjunto de soluções oferecidas pelo Sebrae que
      podem ajudar sua empresa a se desenvolver nos diferentes temas apontados
      neste relatório.
      {themes?.map((theme, i) => (
        <React.Fragment key={i}>
          {solutions !== undefined && solutions[theme] !== undefined && (
            <Paper elevation={0} style={{ margin: "10px" }}>
              <Box className={classes.titleTable}>{theme}</Box>
              <TableContainer style={{ overflow: "hidden" }}>
                <Table aria-label="customized table">
                  <TableBody>
                    {Object.keys(solutions[theme]).map((solCod, i) => (
                      <React.Fragment key={i}>
                        {getCount(i) <= formResponse.form.max_products && (
                          <StyledTableRow key={solCod}>
                            <StyledTableCell
                              component="td"
                              scope="row"
                              width={"50px"}
                            >
                              {solCod}
                            </StyledTableCell>
                            <StyledTableCell component="th" scope="row">
                              {solutions[theme][solCod].publicar_hub ? (
                                <Link href={`https://hub.sebrae.al/pages/view/${solutions[theme][solCod].slug}`} target="_blank" rel="noopener">
                                  {solutions[theme][solCod].nome}
                                </Link>
                              ):(
                                <React.Fragment>
                                  {solutions[theme][solCod].nome}
                                </React.Fragment>
                              )}
                            </StyledTableCell>
                          </StyledTableRow>
                        )}
                      </React.Fragment>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </Paper>
          )}
        </React.Fragment>
      ))}
    </Grid>
  );
};
export default OfferedSolutions;
